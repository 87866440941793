export const removeTrailingSlash = (str) => {
  if (str.length === 1) {
    return str;
  }

  if (str[str.length - 1] === "/") {
    return str.slice(0, -1);
  }

  return str;
};
