import Component from "../classes/Component";
import { mapEach } from "../utils/dom";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        accordionHeaders: "[data-accordion-header]",
      },
    });

    this.addEventListeners();
  }

  addEventListeners() {
    mapEach(this.elements.accordionHeaders, (header) => {
      header.addEventListener("click", () => {
        setTimeout(() => {
          this.emit("resize");
        }, 0);
      });
    });
  }
}
