import Page from "../../classes/Page";

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "months--active",
        monthActive: "month--active",
      },
      element: ".months",
      elements: {
        wrapper: "#january",
        content: ".month__wrapper__inner",
        months: ".month",
      },
    });
  }

  show(url) {
    this.element.classList.add(this.classes.active);
    const id = url.replace("/month/", "").replace("/", "");

    if (this.elements.months instanceof HTMLElement) {
      this.elements.months = [this.elements.months];
    }

    this.elements.wrapper = Array.from(this.elements.months).find(
      (item) => item.id === id
    );

    if (this.elements.wrapper) {
      this.elements.wrapper.classList.add(this.classes.monthActive);
      return super.show(this.elements.wrapper);
    } else {
      // this.body.style.height = `${getOffset(notFound).height}px`;
    }
  }

  async hide() {
    if (!this.elements.wrapper) {
      this.element.classList.remove(this.classes.active);
      return;
    }
    this.element.classList.remove(this.classes.active);
    this.elements.wrapper?.classList.remove(this.classes.monthActive);

    this.elements.wrapper = null;

    return super.hide();
  }

  onResize() {
    if (this.elements.wrapper) {
      super.onResize(this.elements.wrapper);
    } else {
      super.onResize();
    }
  }

  update() {
    super.update();
  }
}
