import Component from "../classes/Component";
import { mapEach } from "../utils/dom";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        dropdowns: "[data-dropdown]",
      },
    });

    this.addEventListeners();
  }

  addEventListeners() {
    const { dropdowns } = this.elements;

    mapEach(dropdowns, (dropdown) => {
      const button = dropdown.querySelector("[data-dropdown-button]");

      button.addEventListener("click", () => {
        if (dropdown.classList.contains("open")) {
          dropdown.classList.remove("open");
        } else {
          dropdown.classList.add("open");
        }
      });
    });
  }
}
