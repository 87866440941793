class HomeState {
  constructor() {
    this.hasEnteredWebsite = false;
  }

  setHasEnteredWebsite(value) {
    this.hasEnteredWebsite = value;
  }
}

const homeState = new HomeState();

export default homeState;