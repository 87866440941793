import gsap from "gsap";
import Component from "../classes/Component";
import { mapEach } from "../utils/dom";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        cookies: "[data-cookie]",
      },
    });

    this.addEventListeners();
  }

  dispenseCookies(emoji, emojiContainer) {
    // create a new div with the emoji, move it above the current emoji, move the current emoji out of the way, then move the new emoji back to the current emoji's position
    const newEmoji = emoji.cloneNode(true);
    emojiContainer.appendChild(newEmoji);

    gsap.to(emoji, {
      duration: 0.5,
      y: 50,
      ease: "power2.out",
    });

    gsap.from(newEmoji, {
      duration: 0.5,
      y: -50,
      delay: 0.5,
      ease: "power2.out",
      onComplete: () => {
        emojiContainer.removeChild(newEmoji);
        gsap.set(emoji, {
          y: 0,
        });
      },
    });
  }

  addEventListeners() {
    mapEach(this.elements.cookies, (cookie) => {
      const cookieButton = cookie.querySelector("button");
      const emoji = cookie.querySelector("[data-dialog-emoji]");
      const emojiContainer = cookie.querySelector("[data-dialog-emoji-con]");

      cookieButton.addEventListener("click", () => {
        this.dispenseCookies(emoji, emojiContainer);
      });
    });
  }
}
