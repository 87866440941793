#ifdef GL_ES
precision highp float;
#endif

#define FREQUENCY 58.0
#define RADIUS 0.35

varying vec2 vTextureCoord;

uniform sampler2D mainImage;
uniform sampler2D depthImage;
uniform vec2 uMouse;

void main() {
  vec3 black = vec3(0.0, 0.0, 0.0);
  vec4 color = texture2D(mainImage, vTextureCoord);
  vec4 depth = texture2D(depthImage, vTextureCoord);

  
  gl_FragColor = texture2D(mainImage, vTextureCoord + uMouse*(depth.r));
  // gl_FragColor = vec4(depth, 1.0);
  // gl_FragColor = depth;
  // gl_FragColor = vec4(vec3(vTextureCoord.x), 1.0);
}

