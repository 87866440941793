import Component from "../classes/Component";
import { TextureLoader } from "curtainsjs";
import gsap from "gsap";
import { mapEach } from "../utils/dom";
import { getVideoPoster, optimizeCloudinaryUrl } from "../utils/cloudinary";
import { BREAKPOINT_TABLET } from "../utils/breakpoints";
import {
  PRELOADER_CARD_SCALE_IN,
  PRELOADER_CARD_SCALE_OUT,
  PRELOADER_OUT,
} from "../utils/easings";
import AutoBind from "../utils/bind";

export default class Preloader extends Component {
  constructor(curtains) {
    super({
      element: "body",
      elements: {
        preloader: ".preloader",
        numberText: "[data-preloader-number]",
        homeHero: ".home__hero",
        homeHeroProgress: "[data-home-hero-progress]",
        homeHeaderText: "[data-intro-header] .c-header__scroll",
        homeHeroEnterButton: ".home__hero__enter",
        preloaderPads: "[data-preloader-pad]",
        heroCreditsSvg: ".home__hero__credits__text svg",
        homeFixedItems: "[data-home-hide]",
        nav: "[data-nav]",
      },
    });

    AutoBind(this);

    this.images = [...document.querySelectorAll("[data-src]")];
    this.videos = [...document.querySelectorAll("[data-video-src]")];

    this.textureElements = [...document.querySelectorAll("[data-texture-id]")];

    this.textures = [];
    this.imageURLs = [
      "https://res.cloudinary.com/dmwfd0zhh/video/upload/q_auto,f_auto,so_0/v1676186158/Null%20Year%20in%20Review%202022/Outro/Outro_Hero_Video_kjlj8u.jpg",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1676886920/Null%20Year%20in%20Review%202022/Environments/Yellow_ydaytx.jpg",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Blue_Left_Wing_chb3cc.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Blue_Right_Wing_fckhfd.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Yellow_Left_Wing_u6uspu.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Yellow_Right_Wing_zas0wy.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Green_Left_Wing_lo5jfq.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Green_Right_Wing_jv2jtk.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Red_Left_Wing_z0csjx.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Red_Right_Wing_znjqhj.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Cyan_Left_Wing_lbejfq.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Cyan_Right_Wing_oaccl9.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Violet_Left_Wing_xmcb3w.png",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Violet_Right_Wing_bruqqe.png",
    ];

    this.loader = new TextureLoader(curtains);

    mapEach(this.textureElements, (textureElement) => {
      const depthMap = textureElement.getAttribute("data-depth-map");
      const mainImage = textureElement.getAttribute("data-main-image");
      const textureId = textureElement.getAttribute("data-texture-id");

      const texture = {
        depthMap,
        mainImage,
        textureId,
      };

      this.textures.push(texture);
    });

    this.length = 0;
    this.texturesTotal = this.textures.length * 2;

    this.url = window.location.pathname;

    this.animateIn();
    this.init();
  }

  getMedia(src) {
    const media = new window.Image();
    media.crossOrigin = "anonymous";
    media.src = src;

    return media;
  }

  createLoader() {
    this.loader.loadImages(
      this.textures.map((texture) => texture.mainImage),
      {
        sampler: "mainImage",
      },
      (texture) => {
        const source = texture.source.src;

        const textureId = this.textures.find(
          (texture) => texture.mainImage === source
        ).textureId;

        const id = `main${textureId}`;

        window.TEXTURES = {
          ...window.TEXTURES,
          [id]: texture,
        };

        this.onAssetLoaded();
      }
    );

    this.loader.loadImages(
      this.textures.map((texture) => texture.depthMap),
      {
        sampler: "depthImage",
      },
      (texture) => {
        const source = texture.source.src;

        const textureId = this.textures.find(
          (texture) => texture.depthMap === source
        ).textureId;

        const id = `depth${textureId}`;

        window.TEXTURES = {
          ...window.TEXTURES,
          [id]: texture,
        };

        this.onAssetLoaded();
      }
    );

    mapEach(this.images, (image) => {
      const dataSrc = image.getAttribute("data-src");
      const src = optimizeCloudinaryUrl(dataSrc);
      const media = this.getMedia(src);

      media.onload = (_) => {
        image.setAttribute("src", src);
        image.removeAttribute("data-src");

        this.onAssetLoaded();
      };

      media.onerror = () => {
        this.onAssetLoaded();
      };
    });

    mapEach(this.imageURLs, (url) => {
      const media = this.getMedia(url);

      media.onload = (_) => {
        this.onAssetLoaded();
      };

      media.onerror = () => {
        this.onAssetLoaded();
      };
    });

    mapEach(this.videos, (video) => {
      const dataSrc = video.getAttribute("data-video-src");

      if (dataSrc.includes("cloudinary")) {
        const src = optimizeCloudinaryUrl(dataSrc);
        const poster = getVideoPoster(dataSrc);
        video.setAttribute("poster", poster);
        video.setAttribute("src", src);
      } else {
        video.setAttribute("src", dataSrc);
      }

      video.removeAttribute("data-video-src");
    });
  }

  onAssetLoaded() {
    const { preloaderPads, numberText } = this.elements;
    this.length += 1;

    const percent =
      this.length /
      (this.images.length + this.texturesTotal + this.imageURLs.length);

    let roundedPercent = Math.round(percent * 100);

    // make roundedPercent multiple of 10
    const flooredPercentage = Math.floor(roundedPercent / 10) * 10;

    // add active state to all the other pads depending on their index and the floored percentage

    mapEach(preloaderPads, (pad, index) => {
      if ((index + 1) * 10 <= flooredPercentage) {
        pad.classList.add("active");
      } else {
        pad.classList.remove("active");
      }
    });

    numberText.innerHTML = `${roundedPercent}%`;

    if (percent === 1) {
      this.onLoaded();
    }
  }

  init() {
    const { homeHero, homeHeaderText, heroCreditsSvg } = this.elements;

    if (this.url === "/" && window.innerWidth > BREAKPOINT_TABLET) {
      heroCreditsSvg.setAttribute("viewBox", "0 0 413 198");
      homeHero.classList.add("masked");
      this.element.classList.add("no-scroll");
      homeHeaderText.innerHTML = "Welcome 👋🏾";

      gsap.set("[data-nav]", {
        opacity: 0,
      });

      gsap.set("[data-header]", {
        opacity: 0,
      });

      gsap.set("[data-hero-dialog]", {
        scale: 0,
        opacity: 0,
      });

      gsap.set(".home__hero__butterfly", {
        scale: 0,
        opacity: 0,
      });

      gsap.set("[data-hero-text-top] path", {
        opacity: 0,
      });

      gsap.set("[data-home-marquee]", {
        opacity: 0,
      });

      gsap.set(".home__hero__credits__text .item", {
        opacity: 0,
      });

      gsap.set("[data-hero-enter]", {
        opacity: 0,
      });

      gsap.set("[data-hero-reel] video", {
        opacity: 0,
        scale: 2,
      });
    }
  }

  createPageAnim() {
    if (this.url !== "/") {
      this.element.classList.remove("no-scroll");
    }
  }

  animateIn() {
    gsap.set(".preloader__inner", {
      opacity: 1,
    });

    gsap.from("[data-preloader-scale]", {
      duration: 0.8,
      scale: 0,
      opacity: 0,
      ease: PRELOADER_CARD_SCALE_IN,
      stagger: {
        amount: 0.4,
      },
      onComplete: () => {
        this.createLoader();
      },
    });
  }

  onLoaded() {
    const { preloader } = this.elements;

    const isHome = this.url === "/";

    if (!isHome) {
      this.emit("completed");
    }

    const tl = gsap.timeline({
      onComplete: () => {
        if (isHome) {
          this.emit("completed");
        }

        this.destroy();
      },
    });

    tl.to("[data-preloader-scale]", {
      duration: 0.6,
      scale: 0,
      opacity: 0,
      ease: PRELOADER_CARD_SCALE_OUT,
    }).to(
      preloader,
      {
        duration: 0.3,
        scaleY: 0,
        transformOrigin: "top",
        ease: PRELOADER_OUT,
      },
      "-=0.2"
    );
  }

  destroy() {
    this.element.removeChild(this.elements.preloader);
    this.createPageAnim();
  }
}
