import Component from "../classes/Component";
import AutoBind from "../utils/bind";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        spotifyLoginButton: "[data-login-spotify]",
      },
    });

    AutoBind(this);

    this.client_id = "3d809b57b2a548289f1f77926b48032f"; // Your client id
    this.redirect_uri = "http://localhost:3000/about";

    this.stateKey = "spotify_auth_state";
    this.scope = "user-read-private user-read-email";

    // this.addEventListeners();
    // this.init();
  }

  getHashParams() {
    let hashParams = {};
    let e,
      r = /([^&;=]+)=?([^&;]*)/g,
      q = window.location.hash.substring(1);
    while ((e = r.exec(q))) {
      hashParams[e[1]] = decodeURIComponent(e[2]);
    }
    return hashParams;
  }

  generateRandomString(length) {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  loginWithSpotify() {
    const state = this.generateRandomString(16);

    localStorage.setItem(this.stateKey, state);

    // rewrite above to use template literals
    const url = `https://accounts.spotify.com/authorize?response_type=token&client_id=${encodeURIComponent(
      this.client_id
    )}&scope=${encodeURIComponent(
      this.scope
    )}&redirect_uri=${encodeURIComponent(
      this.redirect_uri
    )}&state=${encodeURIComponent(state)}`;

    window.location = url;
  }

  async getPlaylists(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    try {
      const response = await fetch("https://api.spotify.com/v1/me/playlists", {
        method: "GET",
        headers,
      });

      const data = await response.json();

      console.log("data", data);
    } catch (error) {
      if (error.response) {
        if (error.response.data.error.message === "The access token expired") {
          this.loginWithSpotify();
        }
      }
    }
  }

  redirectToHome() {
    if (window.location.pathname !== "/") {
      window.location = "/";
    }
  }

  init() {
    const params = this.getHashParams();

    // const access_token = params.access_token;
    // const state = params.state;
    // const storedState = localStorage.getItem(this.stateKey);

    console.log("params", params);

    // set url back to /about
    if (params.access_token) {
      this.getPlaylists(params.access_token);
      window.history.pushState("", "", "/about");
    } else {
      this.redirectToHome();
    }
  }

  addEventListeners() {
    const { spotifyLoginButton } = this.elements;

    spotifyLoginButton.addEventListener("click", this.loginWithSpotify);
  }
}
