import Page from "../../classes/Page";
import { delay } from "../../utils/math";

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "outro--active",
      },
      element: ".outro",
      elements: {
        wrapper: ".outro__wrapper",
        content: ".outro__wrapper__inner",
      },
    });
  }

  show() {
    this.element.classList.add(this.classes.active);

    return super.show();
  }

  async hide() {
    this.element.classList.remove(this.classes.active);

    return super.hide();
  }

  onResize() {
    super.onResize();
  }

  update() {
    super.update();
  }
}
