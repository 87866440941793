import map from "lodash/map";

export const findAncestor = (element, selector) => {
  while (
    (element = element.parentElement) &&
    !(element.matches || element.matchesSelector).call(element, selector)
  ) {
    return element;
  }
};

export const getOffset = (element, scroll = 0) => {
  const box = element.getBoundingClientRect();

  return {
    bottom: box.bottom,
    height: box.height,
    left: box.left,
    right: box.right,
    top: box.top + scroll,
    width: box.width,
  };
};

export function getIndex(node) {
  let index = 0;

  while ((node = node.previousElementSibling)) {
    index++;
  }

  return index;
}

export function mapEach(element, callback) {
  if (element instanceof window.HTMLElement) {
    return [callback(element)];
  }

  return map(element, callback);
}

export const getNextElement = (elem, selector) => {
  let sibling = elem.nextElementSibling;
  if (!selector) return sibling;

  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
};

export const easing = `cubic-bezier(0.19, 1, 0.22, 1)`;

export const setRootColors = (topColor, bottomColor) => {
  const root = document.querySelector(":root");

  // set css variable
  root.style.setProperty("--topColor", topColor);
  root.style.setProperty("--bottomColor", bottomColor);
};

export const setRootAnimationColors = (topColor, bottomColor) => {
  const root = document.querySelector(":root");

  // set css variable
  root.style.setProperty("--startColor", topColor);
  root.style.setProperty("--endColor", bottomColor);
};
