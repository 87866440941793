import Component from "../classes/Component";
import AutoBind from "../utils/bind";
import { BREAKPOINT_TABLET } from "../utils/breakpoints";
import { mapEach } from "../utils/dom";
import homeState from "../state/Home";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        menu: "[data-menu]",
        mobileMenu: "[data-menu-mobile]",
        menuTransition: "[data-menu-transition]",
        menuHide: "[data-menu-hide]",
        menuInner: "[data-menu-inner]",
        pages: ".pages",
        pageInner: ".pages__inner",
        canvas: "#canvas",
      },
    });

    AutoBind(this);

    this.menuActiveClass = "c-menu--active";
    this.delay = 1100;

    this.addEventListeners();
    this.onResize();
  }

  addTransition() {
    const { menu, pages } = this.elements;
    if (window.innerWidth >= BREAKPOINT_TABLET) {
      if (!menu.classList.contains(this.menuActiveClass)) {
        menu.style.transition =
          "transform 0.8s cubic-bezier(0.63, 0, 0.4, 0.99)";
        pages.style.transition =
          "transform 0.75s cubic-bezier(0.63, 0, 0.4, 0.99)";
      } else {
        menu.style.transition =
          "transform 0.8s cubic-bezier(0.63, 0, 0.4, 0.99)";
        pages.style.transition =
          "transform 0.82s cubic-bezier(0.63, 0, 0.4, 0.99)";
      }
    } else {
      menu.style.transition = "none";
      pages.style.transition = "none";
    }
  }

  get menuButtons() {
    return document.querySelectorAll("[data-menu-button]");
  }

  disableMenuButtons() {
    const menuButtons = this.menuButtons;
    menuButtons.forEach((button) => {
      button.setAttribute("disabled", "");
    });
  }

  enableMenuButtons() {
    const menuButtons = this.menuButtons;
    menuButtons.forEach((button) => {
      button.removeAttribute("disabled");
    });
  }

  onResize() {
    const { menu, menuInner } = this.elements;
    this.addTransition();

    if (menu.classList.contains(this.menuActiveClass)) {
      const height = menuInner.scrollHeight;
      this.element.style.height = `${height}px`;
    } else {
      this.element.style.height = "";
    }
  }

  lockScroll() {
    const { menu, menuInner, pageInner, canvas } = this.elements;

    const scrollTop = window.scrollY;
    this.element.style.height = "100vh";

    if (!menu.classList.contains(this.menuActiveClass)) {
      pageInner.style.top = `-${scrollTop}px`;
      menuInner.style.top = 0;
      // canvas.style.top = 0;
    } else {
      menuInner.style.top = `-${scrollTop}px`;
      // canvas.style.top = `-${scrollTop}px`;
      pageInner.style.top = 0;
    }

    this.disableMenuButtons();

    const delay = setTimeout(() => {
      this.enableMenuButtons();
      clearTimeout(delay);
    }, this.delay + 300);
  }

  openMenu() {
    const {
      menu,
      pages,
      mobileMenu,
      menuHide,
      menuInner,
      menuTransition,
      canvas,
    } = this.elements;
    const parallaxItems = document.querySelectorAll("[data-parallax-item]");

    this.addMenuButtonsActiveClass();

    if (window.innerWidth > BREAKPOINT_TABLET) {
      this.lockScroll();
      this.emit("scroll-top");
      pages.style.position = "fixed";
      menu.style.position = "fixed";
      // menuInner.style.maxHeight = "100vh";
      menu.style.maxHeight = "";
      menu.style.transform = "translateY(0)";
      pages.style.transform = "translateY(100%)";
      menuTransition.style.display = "block";
      menuHide.classList.add("hide");
      menu.classList.add(this.menuActiveClass);
      pages.classList.add("pages--hidden");
      this.element.classList.remove("no-scroll");

      mapEach(parallaxItems, (item) => {
        const transform = item.style.transform;

        item.querySelector("img").style.transform = transform;
      });

      const delay = setTimeout(() => {
        menu.style.transform = "unset";
        this.emit("resize");
        menuTransition.style.display = "none";
        menu.style.position = "unset";
        // menuInner.style.maxHeight = "";
        menu.style.maxHeight = "100vh";
        canvas.classList.add("show");
        menuInner.style.zIndex = 1;
        clearTimeout(delay);
        this.emit("menu-opened");
      }, this.delay);
    } else {
      mobileMenu.classList.add(this.menuActiveClass);
    }
  }

  closeMenu() {
    const {
      menu,
      pages,
      menuTransition,
      mobileMenu,
      menuInner,
      menuHide,
      canvas,
    } = this.elements;
    const parallaxItems = document.querySelectorAll("[data-parallax-item]");

    this.removeMenuButtonsActiveClass();

    if (window.innerWidth > BREAKPOINT_TABLET) {
      canvas.classList.remove("show");
      this.lockScroll();
      pages.style.position = "fixed";
      menu.style.position = "fixed";
      menu.style.transform = "translateY(-100%)";
      pages.style.transform = "translateY(0)";
      menuTransition.style.display = "block";
      menu.style.maxHeight = "";
      menuHide.classList.remove("hide");
      menu.classList.remove(this.menuActiveClass);
      pages.classList.remove("pages--hidden");
      mapEach(parallaxItems, (item) => {
        item.querySelector("img").style.transform = "";
      });

      const delay = setTimeout(() => {
        pages.style.transform = "unset";
        this.emit("resize");
        this.emit("scroll-top");
        pages.style.position = "";
        clearTimeout(delay);
        menuInner.style.zIndex = -1;
        this.emit("menu-closed");

        if (!homeState.hasEnteredWebsite && window.location.pathname === "/") {
          this.element.classList.add("no-scroll");
        }
      }, this.delay);
    } else {
      mobileMenu.classList.remove(this.menuActiveClass);
    }
  }

  addMenuButtonsActiveClass() {
    const menuButtons = this.menuButtons;

    menuButtons.forEach((button) => {
      button.classList.add("open");
    });
  }

  removeMenuButtonsActiveClass() {
    const menuButtons = this.menuButtons;

    menuButtons.forEach((button) => {
      button.classList.remove("open");
    });
  }

  addEventListeners() {
    const { menu, mobileMenu } = this.elements;
    const menuButtons = this.menuButtons;

    menuButtons.forEach((button) => {
      // check if button has click event listener already
      if (!button.hasAttribute("data-click-event")) {
        button.addEventListener("click", () => {
          this.addTransition();
          if (
            menu.classList.contains(this.menuActiveClass) ||
            mobileMenu.classList.contains(this.menuActiveClass)
          ) {
            this.closeMenu();
          } else {
            this.openMenu();
          }
        });
        button.setAttribute("data-click-event", "");
      }
    });
  }
}
