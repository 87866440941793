import Component from "../classes/Component";
import AutoBind from "../utils/bind";
import { getNextElement, mapEach } from "../utils/dom";
import AudioFrquency from "./AudioFrquency";
import MarqueeMethods from "./Marquee/Methods";
export default class AudioManager extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        audioGroups: "[data-audio-group]",
        audioSingles: "[data-audio-single]",
      },
    });

    AutoBind(this);

    this.audio = new AudioFrquency();
    this.MarqueeMethods = new MarqueeMethods();
    this.noise = new Audio();
    this.noise.crossOrigin = "anonymous";
    this.noise.volume = 0.3;
    this.noise.src =
      "https://res.cloudinary.com/dmwfd0zhh/video/upload/v1676672836/Null%20Year%20in%20Review%202022/Globals/YIR_Noise_bxeoih.m4a";

    mapEach(this.elements.audioGroups, (group) => {
      this.createAudioGroup(group);
    });

    mapEach(this.elements.audioSingles, (single) => {
      this.createAudioSingle(single);
    });

    this.currentGroup = null;
    this.currentSingle = null;
    this.isPlaying = false;

    this.addEventListeners();
  }

  muteAudio() {
    this.audio.muteAudio();
    this.noise.muted = true;
  }

  unmuteAudio() {
    this.audio.unmuteAudio();
    this.noise.muted = false;
  }

  togglePlay(group) {
    if (this.isPlaying) {
      this.pauseMusic(group);
    } else {
      this.playMusic(null, group);
    }
  }

  pauseMusic(group = null, single = null) {
    if (this.currentGroup) {
      this.currentGroup.classList.remove("playing");
    }

    if (this.currentSingle) {
      this.currentSingle.classList.remove("playing");
    }

    this.audio.pauseAudio();

    this.isPlaying = false;
  }

  playMusic(url, group = null) {
    this.audio.playAudio(url, group);
    clearTimeout(this.autoSkipDelay);

    if (group) {
      mapEach(this.elements.audioGroups, (group) => {
        group.classList.remove("playing");
        group.classList.remove("active");
      });

      this.currentGroup = group;
      group.classList.add("playing");
      group.classList.add("active");
      const firstTrack = group.querySelector("[data-preview-url].first");
      firstTrack && firstTrack.classList.remove("first");
    }
    this.isPlaying = true;
  }

  skipTrack(group, auto = false) {
    const currentTrack = group.querySelector(
      `[data-preview-url="${this.audio.src}"]`
    );
    const nextTrack = getNextElement(currentTrack, "[data-preview-url]");

    if (!nextTrack) {
      const activeTrack = group.querySelector("[data-preview-url].active");
      group.classList.remove("playing");
      this.currentGroup = null;

      if (activeTrack) {
        activeTrack.classList.remove("active");
      }

      return;
    }

    this.showSongIndicator(nextTrack, group);
    this.setTrackTitle(nextTrack, group, false, auto);

    const trackUrl = nextTrack.dataset.previewUrl;

    this.playMusic(trackUrl, group);
  }

  showSongIndicator(track, group = null) {
    if (group) {
      const activeTrack = group.querySelector("[data-preview-url].active");
      const trackIndex = track.dataset.trackIndex;
      const currentIndexEl = group.querySelector("[data-current-index]");

      if (currentIndexEl) {
        const index = Number(trackIndex) + 1;

        index < 10
          ? (currentIndexEl.innerHTML = `0${index}`)
          : (currentIndexEl.innerHTML = index);
      }

      if (activeTrack) {
        activeTrack.classList.remove("active");
      }
    }

    track.classList.add("active");
  }

  autoSkipTrack(group) {
    this.currentGroup.classList.remove("playing");
    this.noise.currentTime = 0;
    this.noise.play();

    this.autoSkipDelay = setTimeout(() => {
      this.noise.pause();
      this.currentGroup.classList.add("playing");
      this.skipTrack(group, true);
      clearTimeout(this.autoSkipDelay);
    }, 600);
  }

  handleTrackListClick(event) {
    const track = event.target.closest("[data-preview-url]");
    const group = track.closest("[data-audio-group]");
    const trackUrl = track.dataset.previewUrl;

    this.showSongIndicator(track, group);
    this.setTrackTitle(track, group);
    this.playMusic(trackUrl, group);
    this.isPlaying = true;
  }

  setTrackTitle(track, group, firstSet = false, auto = false) {
    const currentSongEl = group.querySelector("[data-current-song]");
    const marqueeContainer = group.querySelector("[data-marquee-container]");
    const marquee = marqueeContainer.querySelector("[data-marquee]");
    const marqueeContainerEl = marqueeContainer.querySelector(
      "[data-marquee-translate]"
    );

    const trackTitle =
      track.querySelector("[data-audio-title]").dataset.audioTitle;
    const trackArtist = track.querySelector("[data-audio-artist]")?.dataset
      .audioArtist;

    if (!auto) {
      marquee.innerHTML = "";
      if (trackArtist) {
        currentSongEl.innerHTML = `
      <span class="bold">${trackTitle} — </span>
      <span>${trackArtist}</span>
    `;
      } else {
        currentSongEl.innerHTML = `
      <span>${trackTitle}</span>
    `;
      }

      marquee.innerHTML = currentSongEl.outerHTML;

      marqueeContainerEl.innerHTML = marquee.outerHTML;
    }

    if (!firstSet && !auto) {
      const marqueeContainer = group.querySelector("[data-marquee-container]");
      this.MarqueeMethods.addMarquee(marqueeContainer);
    }

    if (auto) {
      const marqueeContainerElClone = marqueeContainerEl.cloneNode(true);
      const marqueeClone =
        marqueeContainerElClone.querySelector("[data-marquee]");
      const currentSongElClone = marqueeClone.querySelector(
        "[data-current-song]"
      );

      marqueeClone.innerHTML = "";
      if (trackArtist) {
        currentSongElClone.innerHTML = `
      <span class="bold">${trackTitle} — </span>
      <span>${trackArtist}</span>
    `;
      } else {
        currentSongElClone.innerHTML = `
      <span>${trackTitle}</span>
    `;
      }

      marqueeClone.innerHTML = currentSongElClone.outerHTML;
      marqueeContainerElClone.appendChild(marqueeClone);

      marqueeContainer.appendChild(marqueeContainerElClone);

      setTimeout(() => {
        this.MarqueeMethods.createMarquee(marqueeContainer, marqueeClone);
      }, 10);

      marqueeContainerElClone.style.transition = "";
      marqueeContainerElClone.style.transform = "translateY(20px)";

      setTimeout(() => {
        marqueeContainerEl.style.transition = "transform 0.5s linear";
        marqueeContainerElClone.style.transition = "transform 0.5s linear";
        marqueeContainerEl.style.transform = "translateY(-20px)";
        marqueeContainerElClone.style.transform = "translateY(0)";
      }, 100);

      const delay = setTimeout(() => {
        marqueeContainerEl.remove();
        clearTimeout(delay);
      }, 1000);
    }
  }

  createAudioGroup(group) {
    const playAllButton = group.querySelector("[data-play-all]");
    const firstTrack = group.querySelector("[data-preview-url]");
    firstTrack.classList.add("first");

    this.setTrackTitle(firstTrack, group, true);

    const marqueeContainer = group.querySelector("[data-marquee-container]");

    this.MarqueeMethods.addMarquee(marqueeContainer);

    playAllButton.addEventListener("click", () => {
      // check if any track is active
      const activeTrack = group.querySelector("[data-preview-url].active");
      this.currentSingle = null;
      mapEach(this.elements.audioSingles, (single) => {
        single.classList.remove("playing");
        single.classList.remove("active");
      });

      if (!activeTrack) {
        const firstTrack = group.querySelector("[data-preview-url]");
        firstTrack.classList.add("active");
        const trackUrl = firstTrack.dataset.previewUrl;

        if (!firstTrack.classList.contains("first")) {
          this.setTrackTitle(firstTrack, group, true);
        }

        this.playMusic(trackUrl, group);
        this.isPlaying = true;
      } else {
        if (this.currentGroup === group) {
          this.togglePlay(group);
        } else {
          const trackUrl = activeTrack.dataset.previewUrl;
          this.playMusic(trackUrl, group);
          this.isPlaying = true;
        }
      }
    });

    const playButtons = group.querySelectorAll("[data-preview-url-inner]");

    mapEach(playButtons, (button) => {
      button.addEventListener("click", this.handleTrackListClick);
    });
  }

  createAudioSingle(single) {
    const playButton = single.querySelector("[data-play-single]");
    const trackUrl = playButton.dataset.previewUrl;

    playButton.addEventListener("click", () => {
      if (single.classList.contains("playing")) {
        this.pauseMusic(null, single);
      } else {
        if (this.currentSingle === single) {
          this.playMusic(null);
        } else {
          this.playMusic(trackUrl);
        }

        mapEach(this.elements.audioSingles, (single) => {
          single.classList.remove("playing");
          single.classList.remove("active");
          single.style.setProperty("--progress", 0);
          const duration = single.querySelector("[data-duration]");

          if (duration) {
            duration.innerHTML = "00:00";
          }
        });

        single.classList.add("playing");
        single.classList.add("active");
        this.currentSingle = single;

        this.currentGroup = null;
        mapEach(this.elements.audioGroups, (group) => {
          group.classList.remove("playing");
          group.classList.remove("active");
        });
      }
    });
  }

  addEventListeners() {
    this.audio.on("skipTrack", () => {
      this.autoSkipTrack(this.currentGroup);
    });

    this.audio.on("trackEnded", () => {
      this.currentSingle?.classList.remove("playing");
      this.isPlaying = false;
    });

    this.audio.on("trackPaused", () => {
      this.isPlaying = false;

      if (this.currentSingle) {
        this.currentSingle.classList.remove("playing");
      }

      if (this.currentGroup) {
        this.currentGroup.classList.remove("playing");
      }
    });

    this.audio.on("trackPlaying", () => {
      this.isPlaying = true;

      if (this.currentSingle) {
        this.currentSingle.classList.add("playing");
      }

      if (this.currentGroup) {
        this.currentGroup.classList.add("playing");
      }
    });
  }

  update() {
    this.audio.update();

    if (this.isPlaying) {
      this.progress = this.audio.currentTime / this.audio.duration;

      // check if progress is a number
      if (this.progress === this.progress) {
        if (this.currentGroup) {
          this.currentGroup.style.setProperty("--progress", `${this.progress}`);
        }

        if (this.currentSingle) {
          this.currentSingle.style.setProperty(
            "--progress",
            `${this.progress}`
          );

          if (this.currentSingle.querySelector("[data-duration]")) {
            const currentTime = this.audio.currentTime;

            let currentMinutes = Math.floor(currentTime / 60);
            let currentSeconds = Math.floor(currentTime % 60);

            currentMinutes =
              currentMinutes < 10 ? "0" + currentMinutes : currentMinutes;
            currentSeconds =
              currentSeconds < 10 ? "0" + currentSeconds : currentSeconds;

            const duration =
              this.currentSingle.querySelector("[data-duration]");

            duration.innerHTML = `${currentMinutes}:${currentSeconds}`;
          }
        }
      }
    }
  }
}
