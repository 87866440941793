import Component from "../classes/Component";
import { mapEach } from "../utils/dom";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        butterflyToggle: "[data-butterfly-toggle]",
        currentButterflyColor: "[data-butterfly-color]",
      },
    });

    this.addEventListeners();

    this.butterflies = [
      {
        color: "blue",
        leftWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Blue_Left_Wing_chb3cc.png",
        rightWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Blue_Right_Wing_fckhfd.png",
        background: "#00009F",
      },
      {
        color: "yellow",
        leftWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Yellow_Left_Wing_u6uspu.png",
        rightWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Yellow_Right_Wing_zas0wy.png",
        background: "#9F9F00",
      },
      {
        color: "green",
        leftWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Green_Left_Wing_lo5jfq.png",
        rightWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Green_Right_Wing_jv2jtk.png",
        background: "#009F00",
      },
      {
        color: "red",
        leftWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Red_Left_Wing_z0csjx.png",
        rightWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Red_Right_Wing_znjqhj.png",
        background: "#9F0000",
      },
      {
        color: "cyan",
        leftWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Cyan_Left_Wing_lbejfq.png",
        rightWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740516/Null%20Year%20in%20Review%202022/Butterflies/Cyan_Right_Wing_oaccl9.png",
        background: "#009F9F",
      },
      {
        color: "violet",
        leftWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Violet_Left_Wing_xmcb3w.png",
        rightWing:
          "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1678740517/Null%20Year%20in%20Review%202022/Butterflies/Violet_Right_Wing_bruqqe.png",
        background: "#9F009F",
      },
    ];

    this.currentColor = "blue";
    this.init();
  }

  init() {
    const currentColor = localStorage.getItem("butterflyColor");

    if (currentColor) {
      this.currentColor = currentColor;
    }

    this.setElements();
  }

  setElements() {
    const { currentButterflyColor } = this.elements;
    currentButterflyColor.textContent = this.currentColor;
    const leftImages = document.querySelectorAll("[data-butterfly-left]");
    const rightImages = document.querySelectorAll("[data-butterfly-right]");

    const currentButterfly = this.butterflies.find(
      (butterfly) => butterfly.color === this.currentColor
    );

    currentButterflyColor.style.backgroundColor = currentButterfly.background;

    mapEach(leftImages, (image) => {
      image.src = currentButterfly.leftWing;
    });

    mapEach(rightImages, (image) => {
      image.src = currentButterfly.rightWing;
    });
  }

  addEventListeners() {
    const { butterflyToggle } = this.elements;

    butterflyToggle.addEventListener("click", () => {
      const currentButterflyIndex = this.butterflies.findIndex(
        (butterfly) => butterfly.color === this.currentColor
      );

      const nextButterflyIndex =
        currentButterflyIndex + 1 >= this.butterflies.length
          ? 0
          : currentButterflyIndex + 1;

      this.currentColor = this.butterflies[nextButterflyIndex].color;

      localStorage.setItem("butterflyColor", this.currentColor);

      this.setElements();
    });
  }
}
