import Component from "../classes/Component";

export default class Modal extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        modal: "[data-modal]",
        modalBackdrop: "[data-modal-backdrop]",
        modalOpen: "[data-modal-open]",
        modalClose: "[data-modal-close]",
      },
    });

    this.addEventListeners();
  }

  openModal() {
    const { modal } = this.elements;
    modal.classList.add("active");
    this.element.classList.add("no-scroll");

    setTimeout(() => {
      modal.focus();
    }, 100);
  }

  closeModal() {
    const { modal } = this.elements;
    this.element.classList.remove("no-scroll");
    modal.classList.remove("active");
    modal.blur();
  }

  addEventListeners() {
    const { modal, modalOpen, modalClose, modalBackdrop } = this.elements;

    modalOpen.forEach((button) => {
      button.addEventListener("click", this.openModal);
    });

    modalClose.forEach((button) => {
      button.addEventListener("click", this.closeModal);
    });

    modalBackdrop.addEventListener("click", this.closeModal);

    // close on escape key press

    modal.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        this.closeModal();
      }
    });
  }
}
