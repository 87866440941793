import AutoBind from "./utils/bind";
import each from "lodash/each";
import { Curtains } from "curtainsjs";
import Detection from "./classes/Detection";
import Preloader from "./components/Preloader";
import Home from "./pages/Home";
import Feed from "./pages/Feed";
import Outro from "./pages/Outro";
import Lenis from "@studio-freight/lenis";
import SpotifyAuth from "./components/SpotifyAuth";
import Months from "./pages/Months";
import AudioManager from "./components/AudioManager";
import Draggable from "./components/Draggable";
import Accordion from "./components/Accordion";
import DepthMap from "./components/DepthMap";
import Menu from "./components/Menu";
import Dropdown from "./components/Dropdown";
import ScrollSnap from "./components/ScrollSnap";
import Carousel from "./components/Carousel";
import Marquee from "./components/Marquee";
import { mapEach } from "./utils/dom";
import Time from "./components/Time";
import Year from "./components/Year";
import NavToggle from "./components/NavToggle";
import Parallax from "./components/Parallax";
import Transition from "./components/Transition";
import FixedItems from "./components/FixedItems";
import HeroEnter from "./components/HeroEnter";
import Cookie from "./components/Cookie";
import { BREAKPOINT_TABLET } from "./utils/breakpoints";
import Modal from "./components/Modal";
import Router from "./classes/Router";
import homeState from "./state/Home";
import { removeTrailingSlash } from "./utils/link";
import Butterflies from "./components/Butterflies";

class App {
  constructor() {
    AutoBind(this);

    this.url = removeTrailingSlash(window.location.pathname);
    this.innerWidth = window.innerWidth;

    this.mouse = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    this.curtains = new Curtains({
      container: "canvas",
      pixelRatio: Math.min(1.5, window.devicePixelRatio),
    });
    this.menuEl = document.querySelector(".c-menu");
    this.mobileMenuEl = document.querySelector(".menu__mobile");
    this.body = document.querySelector("body");

    this.createPreloader(); // Only used on page load
    this.createMenu(); // In menu which is within layout
    this.createScrollSnap(); //In menu which is within layout
    this.createNavToggle(); // In layout
    this.createTransition(); // In layout
    this.createButterflies();
    this.initPageSpecific();

    this.pages = {
      "/": Home,
      "/feed": Feed,
      "/outro": Outro,
    };

    this.addEventListeners();
    this.initScroll();
    this.scrollToTop();
    this.update();

    this.onResize();

    this.router = new Router({
      onChangeRoute: this.onChange,
      onCloseMenu: this.closeMenu,
    });

    if (
      window.location.pathname !== "/" ||
      window.innerWidth <= BREAKPOINT_TABLET
    ) {
      homeState.setHasEnteredWebsite(true);
    }
  }

  initPageSpecific() {
    this.createDraggable();
    this.createAudioManager();
    this.createAccordion();
    this.createDropdown();
    this.createCarousels();
    this.createTime();
    this.createParallax();
    this.createFixedItems();
    this.createHeroEnter();
    this.createCookies();
    this.createModal();
    this.butterflies.setElements();
  }

  renderPageViaURL() {
    if (this.url.indexOf("/month") > -1) {
      this.page = new Months();
      this.page.onResize();
      return this.page.show(this.url);
    } else if (!Object.keys(this.pages).includes(this.url)) {
      this.router.onChange({
        url: "/404",
      });
    } else {
      const PageClass = this.pages[this.url];
      this.page = new PageClass();
      return this.page.show(this.url);
    }
  }

  initScroll() {
    this.lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
      direction: "vertical", // vertical, horizontal
      gestureDirection: "vertical", // vertical, horizontal, both
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    });

    //get scroll value
    this.lenis.on(
      "scroll",
      ({ scroll, limit, velocity, direction, progress }) => {
        // console.log({ scroll, limit, velocity, direction, progress });
      }
    );
  }

  createPreloader() {
    this.preloader = new Preloader(this.curtains);

    this.preloader.once("completed", this.onPreloaded.bind(this));
  }

  createAudioManager() {
    this.audioManager = new AudioManager();
  }

  createTransition() {
    this.transition = new Transition();
  }

  createFixedItems() {
    this.FixedItems = new FixedItems();
  }

  createButterflies() {
    this.butterflies = new Butterflies();
  }

  onPreloaded() {
    this.createDepthMap();
    this.createMarquee();
    this.onResize();
    this.renderPageViaURL();
  }

  createAccordion() {
    this.accordion = new Accordion();

    this.accordion.on("resize", () => {
      this.onResize();
    });
  }

  createDepthMap() {
    this.depthMap = new DepthMap(this.curtains);
  }

  createScrollSnap() {
    this.scrollSnap = new ScrollSnap();
  }

  createMarquee() {
    this.marquee = new Marquee();
  }

  createHeroEnter() {
    this.heroEnter = new HeroEnter();
  }

  createCookies() {
    this.cookies = new Cookie();
  }

  createModal() {
    this.modal = new Modal();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
    this.lenis.scrollTo(0, {
      immediate: true,
    });
  }

  createMenu() {
    this.menu = new Menu();

    this.menu.on("resize", () => {
      this.onResize();
    });

    this.menu.on("scroll-stop", () => {
      this.lenis.stop();
    });

    this.menu.on("scroll-start", () => {
      this.lenis.start();
    });

    this.menu.on("scroll-top", () => {
      this.scrollToTop();
    });

    this.menu.on("menu-opened", () => {
      this.FixedItems.hide();
    });

    this.menu.on("menu-closed", () => {
      const delay = setTimeout(() => {
        this.FixedItems.show();

        clearTimeout(delay);
      }, 1000);
    });
  }

  createDropdown() {
    this.dropdown = new Dropdown();
  }

  createCarousels() {
    this.carousels = document.querySelectorAll("[data-carousel]");

    this.carouselInstances = [];

    each(this.carousels, (carousel) => {
      const instance = new Carousel(carousel);
      this.carouselInstances.push(instance);
    });
  }

  createTime() {
    const timeElements = document.querySelectorAll("[data-locale]");

    mapEach(timeElements, (element) => {
      new Time(element);
    });

    this.year = new Year();
  }

  createDraggable() {
    const draggableElements = document.querySelectorAll("[data-draggable]");
    mapEach(draggableElements, (element) => {
      new Draggable(element);
    });
  }

  createParallax() {
    const parallaxElements = document.querySelectorAll("[data-parallax]");
    mapEach(parallaxElements, (element) => {
      new Parallax(element);
    });
  }

  createNavToggle() {
    this.navToggle = new NavToggle();

    this.navToggle.on("soundOn", () => {
      this.audioManager.muteAudio();
    });

    this.navToggle.on("soundOff", () => {
      this.audioManager.unmuteAudio();
    });
  }

  /**
   * Events
   */

  onResize() {
    if (this.page) {
      const monthActive = document.querySelector(".month--active");

      if (monthActive) {
        this.page.onResize(monthActive);
      } else {
        this.page.onResize();
      }
    }

    const notFoundActive = document.querySelector(".notfound--active");

    if (notFoundActive) {
      // this.body.style.height = `${getOffset(notFoundActive).height}px`;
    }

    if (this.menu && this.menu.onResize) {
      this.menu.onResize();
    }

    if (this.depthMap && this.depthMap.onResize) {
      this.depthMap.onResize();
    }

    if (this.carouselInstances) {
      each(this.carouselInstances, (instance) => {
        instance.onResize();
      });
    }
  }

  closeMenu() {
    if (
      this.mobileMenuEl.classList.contains("c-menu--active") ||
      this.menuEl.classList.contains("c-menu--active")
    ) {
      this.menu.closeMenu();
    }
  }

  async onChange({ url = null }) {
    this.audioManager.pauseMusic();

    this.initPageSpecific();
    this.createMarquee();

    if (this.mobileMenuEl.classList.contains("c-menu--active")) {
      this.menu.closeMenu();
    }

    url = removeTrailingSlash(url);

    if (
      url === "/" &&
      !homeState.hasEnteredWebsite &&
      !this.menuEl?.classList.contains("c-menu--active")
    ) {
      if (window.innerWidth > BREAKPOINT_TABLET) {
        this.body.classList.add("no-scroll");
      }
    }

    if (url !== "/" && !homeState.hasEnteredWebsite) {
      this.body.classList.remove("no-scroll");
    }

    if (this.menuEl.classList.contains("c-menu--active")) {
      this.menu.closeMenu();
    } else {
      this.scrollToTop();

      if (url !== "/404") {
        this.transition.add();
        const delay = setTimeout(() => {
          this.transition.remove();

          clearTimeout(delay);
        }, 2000);
      }
    }

    this.FixedItems.hide();

    const delay2 = setTimeout(() => {
      this.FixedItems.show();

      clearTimeout(delay2);
    }, 3000);

    this.url = removeTrailingSlash(url);

    await this.page?.hide();

    await this.renderPageViaURL();
    this.menu.addEventListeners();
    this.onResize();
  }

  onWheel(event) {
    if (this.page && this.page.onWheel) {
      this.page.onWheel(event);
    }
  }

  onTouchDown(event) {
    event.stopPropagation();

    if (!Detection.isMobile() && event.target.tagName === "A") return;

    this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY;

    if (this.page && this.page.onTouchDown) {
      this.page.onTouchDown(event);
    }
  }

  onTouchMove(event) {
    event.stopPropagation();

    this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY;

    if (this.page && this.page.onTouchMove) {
      this.page.onTouchMove(event);
    }

    if (this.depthMap && this.depthMap.onTouchMove) {
      this.depthMap.onTouchMove(event);
    }
  }

  onTouchUp(event) {
    event.stopPropagation();

    this.mouse.x = event.changedTouches
      ? event.changedTouches[0].clientX
      : event.clientX;
    this.mouse.y = event.changedTouches
      ? event.changedTouches[0].clientY
      : event.clientY;

    if (this.page && this.page.onTouchUp) {
      this.page.onTouchUp(event);
    }
  }

  onFocus() {
    if (this.carouselInstances) {
      each(this.carouselInstances, (instance) => {
        instance.onFocus();
      });
    }
  }

  onBlur() {
    if (this.carouselInstances) {
      each(this.carouselInstances, (instance) => {
        instance.onBlur();
      });
    }
  }

  /**
   * Loop
   */
  update(time) {
    if (this.page) {
      this.page.update();
    }
    if (this.lenis) {
      this.lenis.raf(time);
    }

    if (this.audioManager) {
      this.audioManager.update();
    }

    if (this.depthMap) {
      this.depthMap.update();
    }

    window.requestAnimationFrame(this.update);
  }

  /**
   * Listeners
   */
  addEventListeners() {
    window.addEventListener(
      "resize",
      () => {
        if (this.innerWidth === window.innerWidth) return;
        this.onResize();
        this.innerWidth = window.innerWidth;
      },
      { passive: true }
    );

    window.addEventListener("mousewheel", this.onWheel, { passive: true });
    window.addEventListener("wheel", this.onWheel, { passive: true });

    window.addEventListener("mousedown", this.onTouchDown, { passive: true });
    window.addEventListener("mousemove", this.onTouchMove, { passive: true });
    window.addEventListener("mouseup", this.onTouchUp, { passive: true });

    window.addEventListener("touchstart", this.onTouchDown, { passive: true });
    window.addEventListener("touchmove", this.onTouchMove, { passive: true });
    window.addEventListener("touchend", this.onTouchUp, { passive: true });

    window.addEventListener("focus", this.onFocus, { passive: true });
    window.addEventListener("blur", this.onBlur, { passive: true });
  }
}

new App();
new SpotifyAuth();
