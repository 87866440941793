import Component from "../../classes/Component";

const animationClassName = 'is-animated'
const visibleClassname = 'is-visible'
export default class extends Component {
  constructor(element) {
    super({
      element: element,
      elements: {
        bitmoji: '.playlist-catalogue-body-creative-playlist-bitmoji',
      },
    });

    this.setupHoverEvents();
    this.timeoutRef = null;
  }

  setupHoverEvents(){
    this.element.addEventListener('mouseover', () => {
      clearTimeout(this.timeoutRef);

      this.elements.bitmoji.classList.add(animationClassName, visibleClassname);
      this.elements.bitmoji.style.animationPlayState = 'running'
    })

    this.element.addEventListener('mouseleave', () => {
      this.elements.bitmoji.classList.remove(visibleClassname)
      this.elements.bitmoji.style.animationPlayState = 'paused'
      this.timeoutRef = setTimeout(() => {
        this.elements.bitmoji.classList.remove(animationClassName)
        this.elements.bitmoji.style.animationPlayState = 'running'
      }, 1000)
    })
  }
}