import Component from "../classes/Component";
import { mapEach, getOffset } from "../utils/dom";
import Prefix from "prefix";

export default class extends Component {
  constructor(element) {
    super({
      element,
      elements: {
        carouselItems: "[data-carousel-item]",
        carouselIndicators: "[data-carousel-indicator]",
        clickLeft: "[data-click-left]",
        clickRight: "[data-click-right]",
      },
    });

    this.currentIndex = 0;

    this.length = this.elements.carouselItems.length;
    this.elementWidth = getOffset(this.element).width;
    this.pause = false;
    this.isIntersecting = false;
    this.delayValue = 10000;

    this.transformPrefix = Prefix("transform");

    this.init();
    this.createIntersection();
    this.detectClickOnEdges();
  }

  onResize() {
    this.elementWidth = getOffset(this.element).width;
    this.element.classList.remove("transition");

    mapEach(this.elements.carouselItems, (element, index) => {
      if (index !== this.currentIndex) {
        this.transform(element, this.elementWidth);
      } else {
        this.transform(element, 0);
      }
    });
  }

  onFocus() {
    this.pause = false;
  }

  onBlur() {
    this.pause = true;
  }

  transform(element, x) {
    // prettier-ignore
    element.style[this.transformPrefix] = `translate3d(${x}px, 0, 0)`;
  }

  autoPlay() {
    if (this.pause || !this.isIntersecting) return;

    this.previousIndex = this.currentIndex;

    if (this.currentIndex >= 0) {
      this.currentIndex += 1;
    }

    if (this.currentIndex === this.length) {
      this.currentIndex = 0;
    }

    this.setActiveIndicator();
    this.animateRight();
  }

  animateRight() {
    const { carouselItems } = this.elements;
    this.element.classList.remove("transition");

    mapEach(carouselItems, (element, index) => {
      if (index !== this.previousIndex) {
        this.transform(element, this.elementWidth);
      }
    });

    setTimeout(() => {
      this.element.classList.add("transition");

      mapEach(carouselItems, (element, index) => {
        if (index === this.currentIndex) {
          this.transform(element, 0);
        }

        if (index === this.previousIndex) {
          this.previousElement = element;
          this.transform(element, -this.elementWidth);
        }
      });
    }, 50);

    // setTimeout(() => {
    //   this.element.classList.remove("transition");
    //   this.transform(this.previousElement, this.elementWidth);
    // }, 1300);
  }

  animateLeft() {
    const { carouselItems } = this.elements;

    this.element.classList.remove("transition");
    mapEach(carouselItems, (element, index) => {
      if (index !== this.previousIndex) {
        this.transform(element, -this.elementWidth);
      }
    });

    setTimeout(() => {
      this.element.classList.add("transition");

      mapEach(carouselItems, (element, index) => {
        if (index === this.currentIndex) {
          this.transform(element, 0);
        }

        if (index === this.previousIndex) {
          this.previousElement = element;
          this.transform(element, this.elementWidth);
        }

        // setTimeout(() => {
        //   this.element.classList.remove("transition");
        //   this.transform(this.previousElement, -this.elementWidth);
        // }, 1300);
      });
    }, 50);
  }

  setActiveIndicator() {
    const { carouselIndicators } = this.elements;

    mapEach(carouselIndicators, (element, index) => {
      if (index === this.currentIndex) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  }

  goToNext() {
    this.previousIndex = this.currentIndex;
    this.currentIndex += 1;

    if (this.currentIndex === this.length) {
      this.currentIndex = 0;
    }

    this.animateRight();
    this.setActiveIndicator();

    clearInterval(this.delay);

    this.delay = setInterval(() => {
      // this.autoPlay();
    }, this.delayValue);
  }

  goToPrevious() {
    this.previousIndex = this.currentIndex;
    this.currentIndex -= 1;

    if (this.currentIndex < 0) {
      this.currentIndex = this.length - 1;
    }

    this.animateLeft();
    this.setActiveIndicator();

    clearInterval(this.delay);

    this.delay = setInterval(() => {
      // this.autoPlay();
    }, this.delayValue);
  }

  init() {
    const { carouselItems, clickLeft, clickRight } = this.elements;

    mapEach(carouselItems, (element, index) => {
      if (index !== this.currentIndex) {
        this.transform(element, this.elementWidth);
      }
    });

    // mapEach(carouselIndicators, (element, index) => {
    //   element.addEventListener("click", () => {
    //     this.previousIndex = this.currentIndex;
    //     this.currentIndex = index;

    //     this.animateRight();
    //     this.setActiveIndicator();

    //     clearInterval(this.delay);

    //     this.delay = setInterval(() => {
    //       this.autoPlay();
    //     }, this.delayValue);
    //   });
    // });

    clickLeft.addEventListener("click", () => {
      this.goToPrevious();
    });

    clickRight.addEventListener("click", () => {
      this.goToNext();
    });

    this.setActiveIndicator();

    this.element.style.opacity = 1;

    this.delay = setInterval(() => {
      // this.autoPlay();
    }, this.delayValue);
  }

  createIntersection() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isIntersecting = true;
        } else {
          this.isIntersecting = false;
        }
      });
    });

    observer.observe(this.element);
  }

  detectClickOnEdges() {
    // const element = this.element;
    // // detect click on element bounding box edges left and right
    // element.addEventListener("click", (e) => {
    //   const rect = getOffset(element);
    //   if (e.clientX < rect.left + rect.width / 2) {
    //     this.goToPrevious();
    //   } else {
    //     this.goToNext();
    //   }
    // });
  }
}
