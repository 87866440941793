import Component from "../classes/Component";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        emoji: "[hero-enter-emoji]",
        label: "[hero-enter-label]",
      },
    });

    this.textsArray = [
      { emoji: "🎧", label: "Headphones plugged in?" },
      { emoji: "🔈", label: "Speakers connected?" },
      { emoji: "☁️", label: "Found a comfy spot?" },
    ];

    setInterval(() => {
      this.switchTexts();
    }, 1500);
  }

  switchTexts() {
    const { emoji, label } = this.elements;

    if (emoji && label) {
      //  infinitely switch between the texts in the textsArray
      emoji.innerHTML = this.textsArray[0].emoji;
      label.innerHTML = this.textsArray[0].label;

      this.textsArray.push(this.textsArray.shift());
    }
  }
}
