import gsap from "gsap";
import Component from "../classes/Component";
import { mapEach } from "../utils/dom";
import { PRELOADER_CARD_SCALE_OUT } from "../utils/easings";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        fixedElements: "[data-fixed-item]",
      },
    });
  }

  hide() {
    const { fixedElements } = this.elements;

    mapEach(fixedElements, (element) => {
      gsap.to(element, {
        duration: 0.3,
        scale: 0,
        opacity: 0,
        ease: PRELOADER_CARD_SCALE_OUT,
      });
    });
  }

  show() {
    const { fixedElements } = this.elements;

    mapEach(fixedElements, (element) => {
      gsap.to(element, {
        duration: 0.3,
        scale: 1,
        opacity: 1,
        ease: PRELOADER_CARD_SCALE_OUT,
      });
    });
  }
}
