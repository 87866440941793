import Component from "../classes/Component";
import AutoBind from "../utils/bind";
import { BREAKPOINT_TABLET } from "../utils/breakpoints";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        gradientBox: '[data-gradient="box"]',
        navSpinner: "[data-nav-spin]",
      },
    });

    this.audio = new Audio();
    this.audio.volume = 0.5;
    // this.volume = 0;
    this.audio.crossOrigin = "anonymous";
    // this.audio.src =
    //   "https://p.scdn.co/mp3-preview/55002f1d364a0447e7b77b73cc5757c7e3b508c4?cid=6fc034abb344433688b2df9d29ff4e00";
    // // "https://p.scdn.co/mp3-preview/a1744d625591a9d2029307418dcaa13b23e462a5?cid=6fc034abb344433688b2df9d29ff4e00";
    this.isGroup = false;
    AutoBind(this);

    this.addEventListeners();
  }

  get src() {
    return this.audio.src;
  }

  get duration() {
    return this.audio.duration;
  }

  get currentTime() {
    return this.audio.currentTime;
  }

  muteAudio() {
    this.audio.muted = true;
  }

  unmuteAudio() {
    this.audio.muted = false;
  }

  createAnalyzer() {
    if (!this.analyser) {
      this.context = new AudioContext();
      this.audioSource = this.context.createMediaElementSource(this.audio);
      this.analyser = this.context.createAnalyser();
      this.audioSource.connect(this.analyser);
      this.analyser.connect(this.context.destination);
      this.analyser.fftSize = 64;

      const bufferLength = this.analyser.frequencyBinCount;
      this.dataArray = new Uint8Array(bufferLength);
    }
  }

  pauseAudio() {
    this.audio.pause();
  }

  playAudio(url, group = null) {
    if (url) {
      this.audio.src = url;
    }
    this.isGroup = group;

    // if (Math.round(this.currentTime === 0)) {
    //   this.audio.volume = 0;
    //   this.volume = 0;
    // }

    this.audio.play();

    this.createAnalyzer();
  }

  resetCurrentTIme() {
    this.audio.currentTime = 0;
  }

  addEventListeners() {
    const { navSpinner } = this.elements;
    this.audio.addEventListener("ended", () => {
      navSpinner.classList.remove("playing");
      if (this.isGroup) {
        this.emit("skipTrack");
      } else {
        this.emit("trackEnded");
      }
    });

    this.audio.addEventListener("play", () => {
      navSpinner.classList.add("playing");
      this.emit("trackPlaying");
    });

    this.audio.addEventListener("pause", () => {
      navSpinner.classList.remove("playing");
      this.emit("trackPaused");
    });

    // this.audio.addEventListener("timeupdate", () => {
    //   const interval = 200; // 200ms interval

    //   if (this.audio.volume === 0) {
    //     const startInterval = setInterval(() => {
    //       if (this.volume < 0.5) {
    //         this.volume += 0.02;
    //         // limit to 2 decimal places
    //         // also converts to string, works ok
    //         this.audio.volume = this.volume.toFixed(2);
    //       } else {
    //         // Stop the setInterval when 0 is reached
    //         clearInterval(startInterval);
    //       }
    //     }, interval);
    //   }

    //   if (
    //     Math.round(this.audio.currentTime) ===
    //     Math.round(this.audio.duration) - 2
    //   ) {
    //     const endInterval = setInterval(() => {
    //       if (this.volume > 0) {
    //         this.volume -= 0.02;
    //         // limit to 2 decimal places
    //         // also converts to string, works ok
    //         this.audio.volume = Math.max(this.volume.toFixed(2), 0);
    //       } else {
    //         // Stop the setInterval when 0 is reached
    //         clearInterval(endInterval);
    //       }
    //     }, interval);
    //   }
    // });
  }

  update() {
    if (this.analyser) {
      this.analyser.getByteFrequencyData(this.dataArray);

      // convert dataArray to one Number
      const sum = this.dataArray.reduce((a, b) => a + b, 0);

      const average = sum / this.dataArray.length;

      // make it a percentage
      const percent = average / 255;

      // change background position of gradient

      if (window.innerWidth > BREAKPOINT_TABLET) {
        this.elements.gradientBox.style.backgroundPosition = `100% ${
          percent * 120
        }%`;
      } else {
        this.elements.gradientBox.style.backgroundPosition = `${
          percent * 120
        }% 100%`;
      }
    }
  }
}
