import Component from "../classes/Component";
import GSAP from "../utils/scrolltrigger";

export default class extends Component {
  constructor(element) {
    super({
      element,
      elements: {
        parralaxItem: "[data-parallax-item]",
      },
    });

    setTimeout(() => {
      this.addParallax();
    }, 0);
  }

  addParallax() {
    const { parralaxItem } = this.elements;
    GSAP.to(parralaxItem, {
      yPercent: -15,
      ease: "none",
      scrollTrigger: {
        trigger: this.element,
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    });
  }
}
