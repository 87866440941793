{
  "creativePlaylists": [
    {
      "id": "Y_FTvxAAACcALYsT",
      "number": 1,
      "alias": "Abstract Onion",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235360/Null%20Year%20in%20Review%202022/Memoji/Abstact_Onion_njpub7.png",
      "job_title": "Product Designer, Àṣà Coterie",
      "creative_practice": "Colors are portals into various core memories. We recognize every color intimately; we've seen it all before. Across aesthetics, processes, and systems, my work aims to remind the customers I serve of that innate familiarity.",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/video/upload/v1676190394/Null%20Year%20in%20Review%202022/Feed/Abstract_Onion_Reel_rhpv6d.mp4",
      "media_width": 800,
      "media_height": 450,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886920/Null%20Year%20in%20Review%202022/Environments/Yellow_ydaytx.jpg",
      "playlist_link": "https://open.spotify.com/playlist/08t6tCQa45dsDyWQAqyUUS?si=8f1b3d47dbf64ec1",
      "playlist_name": "fantasm.",
      "type": [
        "Mood",
        "Life in Nigeria"
      ],
      "playlist_info": "fantasm. is a wide gamut of Afrobeats that highlights uniquely Nigerian experiences—from finding and giving love to dealing with life in the trenches.",
      "external_link": "https://www.asadsgn.com/",
      "gradient_start": "#FFFFFF",
      "gradient_end": "#9F9F9F",
      "embedLink": "https://open.spotify.com/embed/playlist/08t6tCQa45dsDyWQAqyUUS?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/08t6tCQa45dsDyWQAqyUUS?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebbeb31f7ea9a996904affc3753",
      "mediaIsVideo": true,
      "category": "creative"
    },
    {
      "id": "Y_FUpRAAACYALY8T",
      "number": 2,
      "alias": "Priest",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235361/Null%20Year%20in%20Review%202022/Memoji/Priest_pzyvmy.png",
      "job_title": "Concept designer",
      "creative_practice": "Reimagining objects and spaces",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/video/upload/v1676889117/Null%20Year%20in%20Review%202022/Feed/priest_lgdh5m.mp4",
      "media_width": 720,
      "media_height": 720,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Green_t1wfrg.jpg",
      "playlist_link": "https://open.spotify.com/playlist/3ZwWXMvmkJBDEbnCeXGJXp?si=j0qZwK9mQaC9IvhbfbgqtQ",
      "playlist_name": "Vinyl",
      "type": [
        "Mood",
        "Flow"
      ],
      "playlist_info": "The sounds are layered properly in every song, no segment of any track is distracting, it allows me stay locked in for as long as possible",
      "external_link": "https://www.behance.net/otimarvelo572b",
      "gradient_start": "#00FFFF",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/3ZwWXMvmkJBDEbnCeXGJXp?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/3ZwWXMvmkJBDEbnCeXGJXp?utm_source=generator&theme=1",
      "cover": "https://mosaic.scdn.co/640/ab67616d0000b273164f75ca0666c001f0cddd65ab67616d0000b2739fd6f55c35811d79d01f94efab67616d0000b273ab1b13e7ec3ada8d31cdae8bab67616d0000b273ac895ae149e004dcbb6367be",
      "mediaIsVideo": true,
      "category": "creative"
    },
    {
      "id": "Y_FVUhAAACUALZIW",
      "number": 3,
      "alias": "Flotsam",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235360/Null%20Year%20in%20Review%202022/Memoji/Flotsam_fllhl0.png",
      "job_title": "Producer/Mixing Engineer",
      "creative_practice": "Music creation is how I take my daily synaesthetic experiences and arrange them into stories for others to share in.",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676191533/Null%20Year%20in%20Review%202022/Feed/Flotsam_Image_iqr7lp.jpg",
      "media_width": 450,
      "media_height": 600,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Blue_g0wfhj.jpg",
      "playlist_link": "https://open.spotify.com/playlist/4XwVKqGfV7LF7LCNXy5X34?si=c4e05bef01774147",
      "playlist_name": "Micronemesis",
      "type": [
        "Top Picks",
        "Atmospheric"
      ],
      "playlist_info": "The music in this playlist is characterised by broody atmospheres, bright synths, expressive basslines and progressive layering. It's the kind of music you listen to on a road trip while introspecting. It's a psychedelic fusion of grunge, triphop, shoegaze and instrumental music.",
      "external_link": "https://flotsam421.substack.com/",
      "gradient_start": "#FF00FF",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/4XwVKqGfV7LF7LCNXy5X34?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/4XwVKqGfV7LF7LCNXy5X34?utm_source=generator&theme=1",
      "cover": "https://mosaic.scdn.co/640/ab67616d0000b27308674a5155e2f0e47682b792ab67616d0000b2735d48e2f56d691f9a4e4b0bdfab67616d0000b2738a30c3ac2a1e1228f4f45327ab67616d0000b273b2d4f211a6852598b1bdc8c2",
      "mediaIsVideo": false,
      "category": "creative"
    },
    {
      "id": "Y_FU1RAAACUALY_r",
      "number": 4,
      "alias": "Adanna",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235360/Null%20Year%20in%20Review%202022/Memoji/Adanna_zk4jbw.png",
      "job_title": "Product Designer/Illustrator",
      "creative_practice": "My product design practice is fairly structured, but my illustration is often spontaneous. It is a nice balance :) ",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/video/upload/v1676192205/Null%20Year%20in%20Review%202022/Feed/Adanna_Reel_h4rziq.mp4",
      "media_width": 450,
      "media_height": 600,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886920/Null%20Year%20in%20Review%202022/Environments/Yellow_ydaytx.jpg",
      "playlist_link": "https://open.spotify.com/playlist/5ypRwBEc6ChP1lCRYPsym5?si=PPQH6mXQTH6jwgXEeo7Yfw&utm_source=copy-link",
      "playlist_name": "2022 Faves",
      "type": [
        "Top Picks",
        "Adanna"
      ],
      "playlist_info": "This playlist is a mix of different genres—the only overarching theme is the fact that I enjoy them all. Each one brings a different energy to my creative process. Hopefully someone enjoys them as much as I do! :)",
      "external_link": "https://linktr.ee/adxnna",
      "gradient_start": "#FFFF00",
      "gradient_end": "#00FF00",
      "embedLink": "https://open.spotify.com/embed/playlist/5ypRwBEc6ChP1lCRYPsym5?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/5ypRwBEc6ChP1lCRYPsym5?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebbcb6be32bf9efafeb8fbb1a17",
      "mediaIsVideo": true,
      "category": "creative"
    },
    {
      "id": "Y_FVnBAAACUALZNl",
      "number": 5,
      "alias": "Seyi",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235362/Null%20Year%20in%20Review%202022/Memoji/Seyi_dfmct2.png",
      "job_title": "Executive Art Director, Dá Design Studio",
      "creative_practice": "At Dá Design, We use design to help create radical African brands that work.",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/video/upload/v1676192565/Null%20Year%20in%20Review%202022/Feed/Seyi_Reel_uxph4i.mp4",
      "media_width": 720,
      "media_height": 480,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886922/Null%20Year%20in%20Review%202022/Environments/Magenta_01_wo7flj.jpg",
      "playlist_link": "https://open.spotify.com/playlist/4UD4IJzthLAGi3dhEC84gT?si=b4d49593adf34d95",
      "playlist_name": "Mondáy Morning",
      "type": [
        "Top Picks",
        "Seyi"
      ],
      "playlist_info": "It's a little chaotic. Just a little. Settle into it, groove a little bit in the middle and ease out.",
      "external_link": "https://dadesign.studio/",
      "gradient_start": "#00FF00",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/4UD4IJzthLAGi3dhEC84gT?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/4UD4IJzthLAGi3dhEC84gT?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebbfca3513c2995a285caad8f06",
      "mediaIsVideo": true,
      "category": "creative"
    },
    {
      "id": "Y_FVzBAAACUALZRE",
      "number": 6,
      "alias": "Opemipo Aikomo",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235361/Null%20Year%20in%20Review%202022/Memoji/Ope_tqqb7q.png",
      "job_title": "Designer",
      "creative_practice": "Generalist web and brand designer. Specialist software designer. Multidisciplinary design director.",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676192790/Null%20Year%20in%20Review%202022/Feed/Opemipo_Image_nkeqye.jpg",
      "media_width": 800,
      "media_height": 450,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886917/Null%20Year%20in%20Review%202022/Environments/Magenta_02_bigsrx.jpg",
      "playlist_link": "https://open.spotify.com/playlist/0n7WAV5mawQBPj33MJvBlY?si=a932024a71f3460c",
      "playlist_name": "reverse",
      "type": [
        "Mood",
        "Stay at Home"
      ],
      "playlist_info": "A companion playlist from the early pandemic days. Nothing has been the same.",
      "external_link": "https://opemipo.com/",
      "gradient_start": "#00FFFF",
      "gradient_end": "#FF00FF",
      "embedLink": "https://open.spotify.com/embed/playlist/0n7WAV5mawQBPj33MJvBlY?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/0n7WAV5mawQBPj33MJvBlY?utm_source=generator&theme=1",
      "cover": "https://mosaic.scdn.co/640/ab67616d0000b2736df39d0934daf87b8040c868ab67616d0000b273a560539e827f9670c059d051ab67616d0000b273c4502e79a78fdc8b5aaa718bab67616d0000b273d345104b43b93d8a85128cbe",
      "mediaIsVideo": false,
      "category": "creative"
    },
    {
      "id": "Y_FV9RAAACQALZT8",
      "number": 7,
      "alias": "Oluwafemi",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235360/Null%20Year%20in%20Review%202022/Memoji/Femi_oejttx.png",
      "job_title": "Digital Designer",
      "creative_practice": "Chaos and Fun",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/video/upload/v1676193054/Null%20Year%20in%20Review%202022/Feed/Oluwafemi_Reel_yhq99q.mp4",
      "media_width": 740,
      "media_height": 520,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886920/Null%20Year%20in%20Review%202022/Environments/Yellow_ydaytx.jpg",
      "playlist_link": "https://open.spotify.com/playlist/4zPosqWAgxZU0VrKTsg0Bd?si=9f17d97d83334d53",
      "playlist_name": "<Floating>",
      "type": [
        "Mood",
        "Euphoria"
      ],
      "playlist_info": "It makes you float.",
      "external_link": "https://read.cv/Heyfash",
      "gradient_start": "#FFFF00",
      "gradient_end": "#FF00FF",
      "embedLink": "https://open.spotify.com/embed/playlist/4zPosqWAgxZU0VrKTsg0Bd?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/4zPosqWAgxZU0VrKTsg0Bd?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebbeb02a4cad56f0b2c8138d9d1",
      "mediaIsVideo": true,
      "category": "creative"
    },
    {
      "id": "Y_FWIRAAACYALZXD",
      "number": 8,
      "alias": "NIYI OKEOWO",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235361/Null%20Year%20in%20Review%202022/Memoji/Niyi_ov9g3e.png",
      "job_title": "ART DIRECTOR",
      "creative_practice": "Overseeing the art and visual aspect on projects I take on",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/video/upload/v1676888820/Null%20Year%20in%20Review%202022/Feed/niyi_xqwbby.mp4",
      "media_width": 720,
      "media_height": 720,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Red_jtg0wl.jpg",
      "playlist_link": "https://open.spotify.com/playlist/4NRHjkCgpYKGBFBwCM3uHl?si=deeedf73ae194986",
      "playlist_name": "LoveSeeDestroy",
      "type": [
        "Project",
        "LoveSeeDestroy"
      ],
      "playlist_info": "Think of this playlist as journey into the unknown, made it when I was working on my personal project LoveSeeDestroy which reads LSD if you focus on the first letter of each word, I wanted a playlist that could embody the feeling of being on LSD or being in a psychedelic trip.The ultimate empathy playlist when you are on a heroic dose",
      "external_link": "https://helloniyiokeowo.com/",
      "gradient_start": "#00FFFF",
      "gradient_end": "#FF0000",
      "embedLink": "https://open.spotify.com/embed/playlist/4NRHjkCgpYKGBFBwCM3uHl?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/4NRHjkCgpYKGBFBwCM3uHl?utm_source=generator&theme=1",
      "cover": "https://mosaic.scdn.co/640/ab67616d0000b27371d2538887ccc1ba16a635d5ab67616d0000b2739c0e43f4a795df13ea424af9ab67616d0000b273a82585185aa1139839d0f9ecab67616d0000b273abb69dc94a58df6814b68732",
      "mediaIsVideo": true,
      "category": "creative"
    },
    {
      "id": "Y_FWSxAAACQALZaI",
      "number": 9,
      "alias": "Sir Duksalot",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235360/Null%20Year%20in%20Review%202022/Memoji/Duks_xpmt9g.png",
      "job_title": "Visual Artist + Design Knight",
      "creative_practice": "I toil in the dark to serve the light.",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676211390/Null%20Year%20in%20Review%202022/Feed/Duks_Image_gvatip.jpg",
      "media_width": 424,
      "media_height": 600,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Blue_g0wfhj.jpg",
      "playlist_link": "https://open.spotify.com/playlist/5diNuPXixa5cEaJAZQC59C?si=xHNwNxLgQoCoQI5OPcXjrg",
      "playlist_name": "SIRDUKSALOT NULL RADIO",
      "type": [
        "Top Picks",
        "Duks"
      ],
      "playlist_info": "Sounds that found my ears. Some found my heart and stayed.",
      "external_link": "https://www.instagram.com/sirduksalot/",
      "gradient_start": "#0000FF",
      "gradient_end": "#FF0000",
      "embedLink": "https://open.spotify.com/embed/playlist/5diNuPXixa5cEaJAZQC59C?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/5diNuPXixa5cEaJAZQC59C?utm_source=generator&theme=1",
      "cover": "https://mosaic.scdn.co/640/ab67616d0000b2730905700f494c025d93b27a96ab67616d0000b2732a4adc705740ffd82a54e405ab67616d0000b2733bbb371c7e0dc6664a1e997aab67616d0000b27355bde42a370868dee63799fe",
      "mediaIsVideo": false,
      "category": "creative"
    },
    {
      "id": "Y_FWdxAAACQALZda",
      "number": 10,
      "alias": "Yb",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235362/Null%20Year%20in%20Review%202022/Memoji/Yinka_Bernie_vdqm4g.png",
      "job_title": "Creative / Artiste",
      "creative_practice": "Once I am present in the process of whatever art form I am expressing through. I just be present in the moment and let things happen and flow naturally.",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676211504/Null%20Year%20in%20Review%202022/Feed/Yb_Image_kiwowu.jpg",
      "media_width": 600,
      "media_height": 600,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Green_t1wfrg.jpg",
      "playlist_link": "https://open.spotify.com/playlist/2t7Q10BWoGVj9GhIULcfjM?si=0b8b53cfb6414e6e",
      "playlist_name": "In <> Between",
      "type": [
        "Top Picks",
        "Yb"
      ],
      "playlist_info": "It really just sums up all the types of music I listen to. My taste in music is kind of between every type of genre that exists. I would say I enjoy almost all types of music, but this playlist captures what my music library is like.",
      "external_link": "https://www.yinkabernie.com/",
      "gradient_start": "#FFFF00",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/2t7Q10BWoGVj9GhIULcfjM?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/2t7Q10BWoGVj9GhIULcfjM?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb23bfb8bd686ec3af518ae72c",
      "mediaIsVideo": false,
      "category": "creative"
    },
    {
      "id": "Y_FWqBAAACcALZg6",
      "number": 11,
      "alias": "Qvxdri ",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235361/Null%20Year%20in%20Review%202022/Memoji/Qxdri_g0s8wy.png",
      "job_title": "Artist / Designer ",
      "creative_practice": "qvxdri is a visual artist with a background in architecture.  His current artworks are driven by the need to address haunting thoughts and emotions, and are usually used for introspection and/or catharsis. Inspiration is drawn from his reality and poured into these bodies of work that embody mortal narratives that most people can relate to.  He ultimately aims to use his art as a tool for reaching out with his truth, in self-reflection, and acceptance, as well as a medium to connect to other people, and an invitation for them to perhaps discover themselves too.  Qvxdri is currently working on his first few collections and official exhibitions as an artist, working with digital media that incorporates animated visuals with sound design. He further looks to create an immersive canvas that takes you beyond the art form and into a spatial experience.",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/video/upload/v1676212994/Null%20Year%20in%20Review%202022/Feed/Qvxdri_Breel_kapgxj.mp4",
      "media_width": 600,
      "media_height": 600,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886920/Null%20Year%20in%20Review%202022/Environments/Yellow_ydaytx.jpg",
      "playlist_link": "https://open.spotify.com/playlist/3TRbEw4FdQ6Z93QPIU7U7U?si=Xw1tbdQeT0mM3kXf-yMdaA&nd=1",
      "playlist_name": "With Love, From Lagos. #WLFL",
      "type": [
        "Project",
        "#WLFL"
      ],
      "playlist_info": "This playlist was created to accompany my curated art collection, #WLFL, in the celebration of Lagos, its culture and its people.",
      "external_link": "https://www.instagram.com/qvxdri/",
      "gradient_start": "#FFFF00",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/3TRbEw4FdQ6Z93QPIU7U7U?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/3TRbEw4FdQ6Z93QPIU7U7U?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebbcc48f1cf31c9c10f34c78475",
      "mediaIsVideo": true,
      "category": "creative"
    },
    {
      "id": "Y_FXNRAAACQALZqv",
      "number": 12,
      "alias": "Group 256",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235361/Null%20Year%20in%20Review%202022/Memoji/Isaac_ztqu9y.png",
      "job_title": "Digital Designer",
      "creative_practice": "GROUP256© AS AN ALTERNATIVE TO SOULLESS, COOKIE-CUTTER DESIGN SOLUTIONS.",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/video/upload/v1676213526/Null%20Year%20in%20Review%202022/Feed/Isaac_Reel_uqfetm.mp4",
      "media_width": 360,
      "media_height": 480,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Blue_g0wfhj.jpg",
      "playlist_link": "https://open.spotify.com/playlist/1Nl4mo5qA5nONJygyZiJTI?si=4cf6b69cdfee4510",
      "playlist_name": "Gesaffelstein Mix 256",
      "type": [
        "Top Picks",
        "Gesaffelstein"
      ],
      "playlist_info": "A compilation of my favourite tracks from Gesaffelstein. I worked hard on the transitions, and I hope you can tell :)",
      "external_link": "https://www.fayemi.design/",
      "gradient_start": "#00FF00",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/1Nl4mo5qA5nONJygyZiJTI?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/1Nl4mo5qA5nONJygyZiJTI?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb51c21fa028462e5110989e7a",
      "mediaIsVideo": true,
      "category": "creative"
    },
    {
      "id": "Y_FXWxAAACcALZtc",
      "number": 13,
      "alias": "Sahbabii",
      "bitmoji": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676235362/Null%20Year%20in%20Review%202022/Memoji/Sahbabii_frvnng.png",
      "job_title": "Creative Developer",
      "creative_practice": "Creating interactive digital experiences on the web for ambitious brands and individuals.",
      "media_files": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676214176/Null%20Year%20in%20Review%202022/Feed/Seyi_Image_s23yn9.jpg",
      "media_width": 338,
      "media_height": 450,
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886922/Null%20Year%20in%20Review%202022/Environments/Magenta_01_wo7flj.jpg",
      "playlist_link": "https://open.spotify.com/playlist/5LxqHZk52DHoEFSlIdJiAc?si=19eaec9e8e5040a0",
      "playlist_name": "Wave✦✦4",
      "type": [
        "Top Picks",
        "Electronic"
      ],
      "playlist_info": "Some of my favourite electronic tracks.",
      "external_link": "https://www.seyi.dev/",
      "gradient_start": "#FF00FF",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/5LxqHZk52DHoEFSlIdJiAc?utm_source=generator&theme=0",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/5LxqHZk52DHoEFSlIdJiAc?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb0e409f2fc346f342470a690c",
      "mediaIsVideo": false,
      "category": "creative"
    }
  ],
  "nullPlaylists": [
    {
      "id": "Y_FYNhAAACcALZ80",
      "number": 1,
      "playlist_name": "S:N 01/22",
      "playlist_link": "https://open.spotify.com/playlist/7L3m4aHkf6PhfAo3USrLm6?si=e5f5b13fd8d844f7",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Red_jtg0wl.jpg",
      "type": [
        "2022",
        "January"
      ],
      "gradient_start": "#FFFF00",
      "gradient_end": "#FF0000",
      "embedLink": "https://open.spotify.com/embed/playlist/7L3m4aHkf6PhfAo3USrLm6?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/7L3m4aHkf6PhfAo3USrLm6?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebbc72b1e6597f3505436df8177",
      "category": "null"
    },
    {
      "id": "Y_FYdRAAACcALaBW",
      "number": 2,
      "playlist_name": "S:N 02/22",
      "playlist_link": "https://open.spotify.com/playlist/0YxYwkWvwBRzHjDn2wSbpD?si=d3430962ae5643f5",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886920/Null%20Year%20in%20Review%202022/Environments/Yellow_ydaytx.jpg",
      "type": [
        "2022",
        "February"
      ],
      "gradient_start": "#FFFF00",
      "gradient_end": "#9F9F9F",
      "embedLink": "https://open.spotify.com/embed/playlist/0YxYwkWvwBRzHjDn2wSbpD?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/0YxYwkWvwBRzHjDn2wSbpD?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb2a92de545aca887ad78d0417",
      "category": "null"
    },
    {
      "id": "Y_FYqBAAACQALaE7",
      "number": 3,
      "playlist_name": "S:N 03/22",
      "playlist_link": "https://open.spotify.com/playlist/16RKj4XHfYWrIKFmOCyqlO?si=f4462308c40540b4",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Blue_g0wfhj.jpg",
      "type": [
        "2022",
        "March"
      ],
      "gradient_start": "#00FFFF",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/16RKj4XHfYWrIKFmOCyqlO?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/16RKj4XHfYWrIKFmOCyqlO?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb78bd5f3a55038ec3b23b9203",
      "category": "null"
    },
    {
      "id": "Y_FYsBAAACQALaFk",
      "number": 4,
      "playlist_name": "S:N 04/22",
      "playlist_link": "https://open.spotify.com/playlist/4Zmt1RjYlpjL0RaJJeUwv9?si=612dbb549e9349be",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886920/Null%20Year%20in%20Review%202022/Environments/Yellow_ydaytx.jpg",
      "type": [
        "2022",
        "April"
      ],
      "gradient_start": "#FFFF00",
      "gradient_end": "#00FF00",
      "embedLink": "https://open.spotify.com/embed/playlist/4Zmt1RjYlpjL0RaJJeUwv9?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/4Zmt1RjYlpjL0RaJJeUwv9?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebbabeda3a92b765528765a4e59",
      "category": "null"
    },
    {
      "id": "Y_FY3RAAACcALaIw",
      "number": 5,
      "playlist_name": "S:N 05/22",
      "playlist_link": "https://open.spotify.com/playlist/32Qgzky5arWusgOHgo3skW?si=67c9efa367fe4766",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886922/Null%20Year%20in%20Review%202022/Environments/Magenta_01_wo7flj.jpg",
      "type": [
        "2022",
        "May"
      ],
      "gradient_start": "#FF0000",
      "gradient_end": "#000000",
      "embedLink": "https://open.spotify.com/embed/playlist/32Qgzky5arWusgOHgo3skW?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/32Qgzky5arWusgOHgo3skW?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb4f2fb45e2809c7c893f69461",
      "category": "null"
    },
    {
      "id": "Y_FY_BAAACYALaK9",
      "number": 6,
      "playlist_name": "S:N 06/22",
      "playlist_link": "https://open.spotify.com/playlist/5rrVVsocFsigxP6g0pAd5N?si=d15fc7e268244f16",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Red_jtg0wl.jpg",
      "type": [
        "2022",
        "June"
      ],
      "gradient_start": "#00FF00",
      "gradient_end": "#00FFFF",
      "embedLink": "https://open.spotify.com/embed/playlist/5rrVVsocFsigxP6g0pAd5N?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/5rrVVsocFsigxP6g0pAd5N?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb69e524cf45526fa9e7c14e61",
      "category": "null"
    },
    {
      "id": "Y_FZExAAACUALaMm",
      "number": 7,
      "playlist_name": "S:N 07/22",
      "playlist_link": "https://open.spotify.com/playlist/4912vRVNtXF5iD4tSMVKak?si=f272137a212e4735",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Green_t1wfrg.jpg",
      "type": [
        "2022",
        "July"
      ],
      "gradient_start": "#9F9F9F",
      "gradient_end": "#FF0000",
      "embedLink": "https://open.spotify.com/embed/playlist/4912vRVNtXF5iD4tSMVKak?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/4912vRVNtXF5iD4tSMVKak?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb03ba79fbd6d979d2ce78aba5",
      "category": "null"
    },
    {
      "id": "Y_FZKhAAACQALaOS",
      "number": 8,
      "playlist_name": "S:N 08/22",
      "playlist_link": "https://open.spotify.com/playlist/6SiSh1cuzARgyb6TMyJT9w?si=3270425251bb4a3b",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886917/Null%20Year%20in%20Review%202022/Environments/Magenta_02_bigsrx.jpg",
      "type": [
        "2022",
        "August"
      ],
      "gradient_start": "#00FFFF",
      "gradient_end": "#FF00FF",
      "embedLink": "https://open.spotify.com/embed/playlist/6SiSh1cuzARgyb6TMyJT9w?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/6SiSh1cuzARgyb6TMyJT9w?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb13fd2e98475393ee28fe027d",
      "category": "null"
    },
    {
      "id": "Y_FZQxAAACYALaQP",
      "number": 9,
      "playlist_name": "S:N 09/22",
      "playlist_link": "https://open.spotify.com/playlist/6zvshTXY98Iv1FPK4A4WG6?si=278a11beb7954ec2",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886920/Null%20Year%20in%20Review%202022/Environments/Yellow_ydaytx.jpg",
      "type": [
        "2022",
        "September"
      ],
      "gradient_start": "#FFFF00",
      "gradient_end": "#FF0000",
      "embedLink": "https://open.spotify.com/embed/playlist/6zvshTXY98Iv1FPK4A4WG6?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/6zvshTXY98Iv1FPK4A4WG6?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb62846acf0b73880dcb53130c",
      "category": "null"
    },
    {
      "id": "Y_FZWxAAACUALaR9",
      "number": 10,
      "playlist_name": "S:N 10/22",
      "playlist_link": "https://open.spotify.com/playlist/7z92lvkv8jijpUwtoWWrvS?si=f2b74e14cbc14f0f",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886917/Null%20Year%20in%20Review%202022/Environments/Magenta_02_bigsrx.jpg",
      "type": [
        "2022",
        "October"
      ],
      "gradient_start": "#00FF00",
      "gradient_end": "#FF00FF",
      "embedLink": "https://open.spotify.com/embed/playlist/7z92lvkv8jijpUwtoWWrvS?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/7z92lvkv8jijpUwtoWWrvS?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb9e7e51e60b602626b8d6d662",
      "category": "null"
    },
    {
      "id": "Y_FZcBAAACYALaTq",
      "number": 11,
      "playlist_name": "S:N 11/22",
      "playlist_link": "https://open.spotify.com/playlist/3QPVNMZgyYhVEIbliDRtlp?si=176b0a806d144246",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Blue_g0wfhj.jpg",
      "type": [
        "2022",
        "November"
      ],
      "gradient_start": "#00FF00",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/3QPVNMZgyYhVEIbliDRtlp?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/3QPVNMZgyYhVEIbliDRtlp?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebbc6fea4e040c72736a39ff225",
      "category": "null"
    },
    {
      "id": "Y_FZmxAAACYALaWo",
      "number": 12,
      "playlist_name": "S:N 12/22",
      "playlist_link": "https://open.spotify.com/playlist/2nMU9A7MRFyyN3YdDCcITv?si=1dbf548e7c134189",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Green_t1wfrg.jpg",
      "type": [
        "2022",
        "December"
      ],
      "gradient_start": "#00FFFF",
      "gradient_end": "#FF0000",
      "embedLink": "https://open.spotify.com/embed/playlist/2nMU9A7MRFyyN3YdDCcITv?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/2nMU9A7MRFyyN3YdDCcITv?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb21214b8073d9bc12d4f9f632",
      "category": "null"
    },
    {
      "id": "Y_FZsxAAACUALaYY",
      "number": 13,
      "playlist_name": "S:N '22",
      "playlist_link": "https://open.spotify.com/playlist/6pd2rOMf4RlDoYTwM8mSFX?si=7d6ddf6c4ccb46cf",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886920/Null%20Year%20in%20Review%202022/Environments/Yellow_ydaytx.jpg",
      "type": [
        "2022",
        "Compilation"
      ],
      "gradient_start": "#00FFFF",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/6pd2rOMf4RlDoYTwM8mSFX?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/6pd2rOMf4RlDoYTwM8mSFX?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebbaca0cfd9f262b559581504f8",
      "category": "null"
    },
    {
      "id": "Y_FZzxAAACYALaad",
      "number": 14,
      "playlist_name": "Drums, Chants, Colour.",
      "playlist_link": "https://open.spotify.com/playlist/0WpuzVwpyVYCRohh6scOdP?si=7d744d2b278148f5",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886921/Null%20Year%20in%20Review%202022/Environments/Red_jtg0wl.jpg",
      "type": [
        "Top Picks"
      ],
      "gradient_start": "#FFFF00",
      "gradient_end": "#FF0000",
      "embedLink": "https://open.spotify.com/embed/playlist/0WpuzVwpyVYCRohh6scOdP?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/0WpuzVwpyVYCRohh6scOdP?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebb1b8ddbcab032322f339828bc",
      "category": "null"
    },
    {
      "id": "Y_FZ4xAAACQALab7",
      "number": 15,
      "playlist_name": "Altè",
      "playlist_link": "https://open.spotify.com/playlist/3ACKUgLvquqv5GWqasxciH?si=4f202ed22c654b58",
      "background_image": "https://res.cloudinary.com/dmwfd0zhh/image/upload/v1676886917/Null%20Year%20in%20Review%202022/Environments/Magenta_02_bigsrx.jpg",
      "type": [
        "Top Picks"
      ],
      "gradient_start": "#FF00FF",
      "gradient_end": "#0000FF",
      "embedLink": "https://open.spotify.com/embed/playlist/3ACKUgLvquqv5GWqasxciH?utm_source=generator&theme=1",
      "mobileEmbedLink": "https://open.spotify.com/embed/playlist/3ACKUgLvquqv5GWqasxciH?utm_source=generator&theme=1",
      "cover": "https://i.scdn.co/image/ab67706c0000bebbdafecb0ad8060e974626ba2a",
      "category": "null"
    }
  ],
  "numberOfPlaylists": 28,
  "numberOfContributors": 14
}