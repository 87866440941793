import { getOffset } from "../../utils/dom";

export default class {
  constructor() {}

  addMarquee(element, delay = 1000) {
    const marquee = element.querySelector("[data-marquee]");
    const marqueeItem = marquee.querySelectorAll("[data-marquee-item]");

    const wrapperWidth = getOffset(element).width;
    const marqueeWidth = getOffset(marquee).width;

    if (marqueeWidth > wrapperWidth) {
      if (marqueeItem.length === 1) {
        // add a second marqueeItem
        const clone = marqueeItem[0].cloneNode(true);
        marquee.appendChild(clone);
      }

      setTimeout(() => {
        marquee.classList.add("active");
      }, 1000);
    } else {
      marquee.classList.remove("active");
    }
  }

  createMarquee(element, marquee) {
    const wrapperWidth = getOffset(element).width;
    const marqueeWidth = getOffset(marquee).width;
    const marqueeItem = marquee.querySelectorAll("[data-marquee-item]");

    if (marqueeWidth > wrapperWidth) {
      if (marqueeItem.length === 1) {
        // add a second marqueeItem
        const clone = marqueeItem[0].cloneNode(true);
        marquee.appendChild(clone);
      }

      setTimeout(() => {
        marquee.classList.add("active");
      }, 1000);
    } else {
      marquee.classList.remove("active");
    }
  }
}
