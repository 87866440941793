import Page from "../../classes/Page";
import gsap from "gsap";
import {HERO_ENTRY_1, HERO_ENTRY_2, HERO_ENTRY_3, PRELOADER_CARD_SCALE_IN} from "../../utils/easings";
import homeState from "../../state/Home";

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "home--active",
      },
      element: ".home",
      elements: {
        body: document.querySelector("body"),
        wrapper: ".home__wrapper",
        content: ".home__wrapper__inner",
        homeHeroEnterButton: ".home__hero__enter",
        homeHeroProgress: "[data-home-hero-progress]",
        homeHero: ".home__hero",
        homeFixedItems: "[data-home-hide]",
        homeHeaderText: "[data-intro-header] .c-header__scroll",
        heroCreditsSvg: ".home__hero__credits__text svg",
        heroOuter: ".home__hero__outer",
        heroInner: ".home__hero__inner"
      },
    });

    this.createIntroAnim();
  }

  createIntroAnim() {
    const { homeHero, homeFixedItems } = this.elements;

    // gsap.set(nav, {
    //   zIndex: 6,
    // });

    const timeline = gsap.timeline({
      paused: true
    })

    this.introAnimation = timeline;

    window.introAnimation = timeline;
    timeline.to("[data-hero-dialog]", {
      duration: 0.4,
      scale: 0,
      opacity: 0,
      ease: PRELOADER_CARD_SCALE_IN,
      stagger: {
        amount: 0.1,
      },
      onComplete: () => {
        homeHero.classList.remove("masked");
        homeFixedItems.forEach((item) => {
          item.remove();
        });
      },
    });

    timeline.to(".home__hero__butterfly", {
      duration: 0.4,
      scale: 0,
      opacity: 0,
      ease: PRELOADER_CARD_SCALE_IN,
    }, 0);

    // gsap.to(".home__hero__butterfly--top", {
    //   duration: 0.4,
    //   delay: 0.1,
    //   y: "-70vh",
    //   ease: BUTTERFLY_EXIT,
    // });

    // gsap.to(".home__hero__butterfly--left", {
    //   duration: 0.4,
    //   delay: 0.1,
    //   x: "-70vw",
    //   ease: BUTTERFLY_EXIT,
    //   onComplete: () => {
    //     gsap.set(nav, {
    //       zIndex: 9,
    //     });
    //   },
    // });

    // gsap.to(".home__hero__butterfly--right", {
    //   duration: 0.4,
    //   delay: 0.1,
    //   x: "70vw",
    //   ease: BUTTERFLY_EXIT,
    // });

    timeline.to(".home__hero__credits__text", {
      duration: 0.4,
      opacity: 0,
      delay: 0.1,
      ease: HERO_ENTRY_2,
    }, 0);

    timeline.to("[data-hero-reel] video", {
      duration: 0.4,
      delay: 0.1,
      opacity: 0,
      ease: HERO_ENTRY_3,
    }, 0);

    timeline.to("[data-hero-text-top]", {
      duration: 0.4,
      opacity: 0,
      delay: 0.1,
      ease: HERO_ENTRY_2,
    }, 0);

    timeline.to("[data-hero-enter]", {
      duration: 0.4,
      opacity: 0,
      delay: 0.1,
      ease: HERO_ENTRY_2,
    }, 0);
  }

  playIntroAnimation() {
    const { homeHeaderText } = this.elements;
    
    this.introAnimation.play();
    homeState.setHasEnteredWebsite(true);

    setTimeout(() => {
      this.elements.body.classList.remove("no-scroll");
      homeHeaderText.innerHTML = "Scroll to continue";
    }, 1000);
  }

  playRenderAnim(){
    const { homeHeroEnterButton, homeHeroProgress } = this.elements;

    gsap.to(["[data-nav]", "[data-header"], {
      duration: 0.3,
      opacity: 1,
      ease: HERO_ENTRY_1,
    });

    gsap.to("[data-hero-text-top] path", {
      duration: 0.8,
      opacity: 1,
      delay: -0.2,
      stagger: {
        amount: 0.2,
      },
      ease: HERO_ENTRY_2,
    });

    gsap.to(".home__hero__butterfly", {
      duration: 0.6,
      delay: 0.2,
      scale: 1,
      opacity: 1,
      ease: PRELOADER_CARD_SCALE_IN,
    });

    gsap.to("[data-home-marquee]", {
      duration: 0.1,
      opacity: 1,
      delay: 0.3,
      ease: HERO_ENTRY_3,
    });

    gsap.to(".home__hero__credits__text .item", {
      duration: 0.6,
      opacity: 1,
      delay: 0.4,
      stagger: {
        amount: 0.2,
      },
      ease: HERO_ENTRY_2,
    });

    gsap.to("[data-hero-reel] video", {
      duration: 0.9,
      delay: 0.3,
      scale: 1,
      opacity: 1,
      ease: HERO_ENTRY_3,
    });

    gsap.to("[data-hero-enter]", {
      duration: 0.4,
      opacity: 1,
      delay: 0.7,
      ease: HERO_ENTRY_2,
      onComplete: () => {
        homeHeroEnterButton.addEventListener("click", () => {
          this.playIntroAnimation();
        });

        homeHeroEnterButton.addEventListener("mouseenter", () => {
          if (this.mouseLeave) {
            this.mouseLeave.kill();
          }

          this.mouseEnter = gsap.to(homeHeroProgress, {
            duration: 5,
            scaleX: 1,
            ease: HERO_ENTRY_2,

            onComplete: () => {
              this.playIntroAnimation();
            },
          });
        });

        homeHeroEnterButton.addEventListener("mouseleave", () => {
          this.mouseEnter.kill();
          this.mouseLeave = gsap.to(homeHeroProgress, {
            duration: 0.2,
            scaleX: 0,
            ease: HERO_ENTRY_2,
            onComplete: () => {
            },
          });
        });
      },
    });

    gsap.to("[data-hero-dialog]", {
      duration: 0.6,
      scale: 1,
      opacity: 1,
      delay: 0.9,
      ease: PRELOADER_CARD_SCALE_IN,
      stagger: {
        amount: 0.1,
      },
    });
  }

  show() {
    const { homeHero, homeFixedItems, homeHeaderText, heroCreditsSvg, heroOuter, heroInner } = this.elements;

    if(homeState.hasEnteredWebsite){
      this.introAnimation.seek("end");

      this.elements.body.classList.remove("no-scroll");
      homeHeaderText.innerHTML = "Scroll to continue";

      homeHero.classList.remove("masked");
      homeFixedItems.forEach((item) => {
        item.remove();
      });
    }else{
      heroCreditsSvg.setAttribute("viewBox", "0 0 413 198");

      heroOuter.style.transitionDuration = "0s";
      heroInner.style.transitionDuration = "0s";

      homeHero.classList.add("masked");

      //Run on next tick to prevent the hero from animating on load
      setTimeout(() => {
        heroOuter.style.transitionDuration = "1.6s";
        heroInner.style.transitionDuration = "1s";
      })

      homeHeaderText.innerHTML = "Welcome 👋🏾";

      this.playRenderAnim();
    }

    this.element.classList.add(this.classes.active);

    return super.show();
  }

  async hide() {
    this.element.classList.remove(this.classes.active);

    return super.hide();
  }

  onResize() {
    super.onResize();
  }

  update() {
    super.update();
  }
}
