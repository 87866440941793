import each from "lodash/each";
import {mapEach} from "../utils/dom";
import {getVideoPoster, optimizeCloudinaryUrl} from "../utils/cloudinary";
import AutoBind from "../utils/bind";
import {removeTrailingSlash} from "../utils/link";

const pagesInnerClass = ".pages__inner";

class Router {
  constructor({ onChangeRoute, onCloseMenu }) {
    this.pageDataCache = {};

    this.pagesInner = document.querySelector(pagesInnerClass);

    AutoBind(this);

    this.addEventListeners();
    this.addLinksEventsListeners();
    this.onChangeRoute = onChangeRoute;
    this.onCloseMenu = onCloseMenu;
    this.url = removeTrailingSlash(window.location.pathname);
  }

  async fetchAndExtractPage(url) {
    let response;
    if (url === "/") {
      response = await fetch(`/index.html`);
    } else {
      response = await fetch(`${url}/index.html`);
    }

    const pageHTML = await response.text();

    const randomDiv = document.createElement("div");
    randomDiv.style.display = "none";

    randomDiv.innerHTML = pageHTML;
    const pageInner = randomDiv.querySelector(pagesInnerClass);

    this.images = [...randomDiv.querySelectorAll("[data-src]")];
    this.videos = [...randomDiv.querySelectorAll("[data-video-src]")];

    mapEach(this.images, (image) => {
      const dataSrc = image.getAttribute("data-src");
      const src = optimizeCloudinaryUrl(dataSrc);

      image.setAttribute("src", src);
      image.removeAttribute("data-src");
    });

    mapEach(this.videos, (video) => {
      const dataSrc = video.getAttribute("data-video-src");

      if (dataSrc.includes("cloudinary")) {
        const src = optimizeCloudinaryUrl(dataSrc);
        const poster = getVideoPoster(dataSrc);
        video.setAttribute("poster", poster);
        video.setAttribute("src", src);
      } else {
        video.setAttribute("src", dataSrc);
      }

      video.removeAttribute("data-video-src");
    });

    randomDiv.remove();
    return pageInner.innerHTML;
  }

  async onChange({ push = true, url = null }) {
    url = url.replace(window.location.origin, "");

    if (this.isFetching) return;

    if (this.url === url) {
      this.onCloseMenu();
      return;
    }
    this.url = url;

    this.isFetching = true;

    if (push) {
      window.history.pushState({}, document.title, url);
    }

    if (!this.pageDataCache[url]) {
      this.pageDataCache[url] = await this.fetchAndExtractPage(url);
    }

    this.pagesInner.innerHTML = this.pageDataCache[url];

    this.isFetching = false;

    this.onChangeRoute({
      url,
    });

    this.addLinksEventsListeners();
    this.delay = setTimeout(() => {
      this.playVideos();
      clearTimeout(this.delay);
    }, 100);
    //on render page
  }

  onPopState() {
    this.onChange({
      url: window.location.pathname,
      push: false,
    });
  }

  playVideos() {
    this.autoPlayVideos = [...document.querySelectorAll("[autoplay]")];

    mapEach(this.autoPlayVideos, (video) => {
      video?.play();
    });
  }

  addLinksEventsListeners() {
    const links = document.querySelectorAll("a");

    each(links, (link) => {
      const isLocal = link.href.indexOf(window.location.origin) > -1;
      const isAllowed = link.dataset.allow;
      const isNewTab = link.target === "_blank";

      if (isLocal && !isAllowed && !isNewTab) {
        link.onclick = (event) => {
          event.preventDefault();

          this.onChange({
            url: link.href,
          });
        };
      } else if (
        link.href.indexOf("mailto") === -1 &&
        link.href.indexOf("tel") === -1
      ) {
        if (!isAllowed) {
          link.rel = "noopener";
          link.target = "_blank";
        }
      }
    });
  }

  addEventListeners() {
    window.addEventListener("popstate", this.onPopState, { passive: true });
  }
}

export default Router;
