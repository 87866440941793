import Component from "../classes/Component";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        pages: ".pages",
        pageTransition: "[data-page-transition]",
      },
    });
  }

  add() {
    const { pageTransition, pages } = this.elements;
    pageTransition.classList.add("active");
    pages.classList.add("no-scroll");
  }

  remove() {
    const { pageTransition, pages } = this.elements;
    pageTransition.classList.remove("active");
    pages.classList.remove("no-scroll");
  }
}
