import Component from "../classes/Component";
import { getOffset } from "../utils/dom";

export default class extends Component {
  constructor() {
    super({
      element: "[data-snap-container]",
      elements: {
        snapEl: "[data-snap]",
        snapItems: "[data-snap-item]",
        snapText: "[data-snap-text]",
        indicators: "[data-snap-indicator]",
      },
    });

    // console.log(this.elements.snapItems);

    this.checkVisible();
    this.addEventListeners();
    this.isScrolling = false;
  }

  checkVisible() {
    const { snapItems, snapText, indicators } = this.elements;

    snapItems.forEach((item, index) => {
      // check which snap item is fully visible horixzontally in the snap container without any overlap
      const rect = getOffset(item);

      if (
        rect.left >= 0 &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      ) {
        snapText.innerHTML = item.dataset.snapName;
        const indicator = indicators[index];
        indicator.classList.add("active");

        // remove active class from all other indicators
        indicators.forEach((indicator) => {
          if (indicator !== indicators[index]) {
            indicator.classList.remove("active");
          }
        });
      }
    });
  }

  addEventListeners() {
    const { snapEl, snapText } = this.elements;

    // snapEl.addEventListener("touchend", this.checkVisible);
    snapEl.addEventListener("scroll", (e) => {
      //  use setTimeout to prevent the checkVisible function from being called too often
      snapText.style.opacity = 0;

      window.clearTimeout(this.isScrolling);

      this.isScrolling = setTimeout(() => {
        snapText.style.opacity = 1;
        this.checkVisible();
      }, 100);
    });
  }
}
