import Component from "../../classes/Component";
import feed from "feed";

const activeClassname = 'is-expanded'
const mobilePlaylistClassName = 'mobile-playlist'

export default class extends Component {
  constructor({element, onClickAbout}) {
    super({
      element: element,
      elements: {
        expand: '[data-expand]',
        collapse: '[data-collapse]',
        share: '[data-share-playlist]',
        about: '.mobile-playlist-controls-about',
      },
    });

    const playlistId = this.element.dataset.playlistId;
    this.playlist = feed.creativePlaylists.concat(feed.nullPlaylists).find(playlist => playlist.id === playlistId);

    this.onClickAbout = onClickAbout;

    this.setupEventListeners();

    const search = new URLSearchParams(window.location.search);
    const urlId = search.get('pid');
    if(urlId === playlistId){
      this.expand();

      setTimeout(() => {
        //It doesn't scroll directly on mount so I had to add this sigh
        this.element.scrollIntoView({
          behavior: "smooth"
        })
      }, 1000)
    }
  }

  expand(){
    const currentActivePlaylist = document.querySelector(`.${mobilePlaylistClassName}.${activeClassname}`);

    if(currentActivePlaylist){
      currentActivePlaylist.classList.remove(activeClassname)
    }

    window.history.pushState("", "", `/feed?pid=${this.playlist.id}`)

    this.element.classList.add(activeClassname)
  }

  collapse(){
    const currentUrl = window.location.href.split('?')[0];

    window.history.pushState("", "", currentUrl)

    this.element.classList.remove(activeClassname)
  }

  setupEventListeners(){
    this.elements.collapse.addEventListener('click', this.collapse)

    this.elements.expand.addEventListener('click', this.expand)

    this.elements.share.addEventListener('click', () => {
      navigator
        .share({
          url: window.location.href
        })
        .then(() => console.log('Successful share! 🎉'))
        .catch(err => console.error(err));
    })

    this.elements.about?.addEventListener('click', () => this.onClickAbout(this.playlist))
  }
}