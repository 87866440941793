import Component from "../classes/Component";
import { mapEach } from "../utils/dom";

export default class Year extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        copyrightYear: "[data-year]",
        date: "[data-date]",
      },
    });

    this.updateCopyrightYear();
    this.updateFullYear();
  }

  get currentDate() {
    const date = new Intl.DateTimeFormat([], {
      timeZone: "Africa/Lagos",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })
      .format(new Date())
      .split("/");

    return {
      day: date[0],
      month: date[1],
      year: date[2],
    };
  }

  updateCopyrightYear() {
    mapEach(this.elements.copyrightYear, (element) => {
      element.innerHTML = this.currentDate.year;
    });
  }

  updateFullYear() {
    const { day, month, year } = this.currentDate;

    mapEach(this.elements.date, (element) => {
      element.innerHTML = `${day}.${month}.${year}`;
    });
  }
}
