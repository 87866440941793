import Component from "../../classes/Component";
import AutoBind from "../../utils/bind";
import { mapEach } from "../../utils/dom";
import Methods from "./Methods";
export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        marquees: "[data-marquee-container]",
      },
    });

    this.marqueeMethods = new Methods();
    AutoBind(this);
    this.init();
  }

  init() {
    const marquees = this.elements.marquees;

    mapEach(marquees, (marquee) => {
      if (marquee.hasAttribute("data-auto-marquee")) {
        marquee.classList.add("active");
      }

      if (marquee.hasAttribute("data-once-marquee")) {
        this.marqueeMethods.addMarquee(marquee);
      }
    });
  }
}
