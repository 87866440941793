import Component from "../../classes/Component";
import AutoBind from "../../utils/bind";
import Lenis from "@studio-freight/lenis";
import Plane from "./Plane";

export default class extends Component {
  constructor(curtains) {
    super({
      element: "body",
      elements: {
        planes: "[data-plane]",
      },
    });

    AutoBind(this);

    this.curtains = curtains;

    this.createPlanes();
  }

  createPlanes() {
    this.planes = [];

    const { planes } = this.elements;

    planes.forEach((plane) => {
      this.planes.push(
        new Plane({
          curtains: this.curtains,
          plane,
          textureId: plane.dataset.textureId,
        })
      );
    });
  }

  onTouchMove(event) {
    this.planes.forEach((plane) => {
      plane.onTouchMove(event);
    });
  }

  onResize() {
    this.planes.forEach((plane) => {
      plane.onResize();
    });
  }

  update() {
    this.planes.forEach((plane) => {
      plane.update();
    });
  }
}
