import Component from "../../classes/Component";

const initialPosition = {
  right: 120,
  top: 296
}

export default class extends Component {
  constructor() {
    const parentElement = "[data-card-id='playlist-selector']";
    super({
      element: parentElement,
      elements: {
        name: `${parentElement} [data-name]`,
        owner: `${parentElement} [data-owner]`,
        nextUp: `${parentElement} [data-next-up]`,
      },
    });
  }

  setPlaylistSelector(playlist, nextUp){
    this.elements.name.textContent = playlist.playlist_name;
    this.elements.owner.textContent = `by ${playlist.alias || "Studio Null"}`;
    this.elements.nextUp.textContent = nextUp;

    this.element.style.left = `unset`
    this.element.style.right = `${initialPosition.right}px`
    this.element.style.top = `${initialPosition.top}px`
  }
}