import Component from "../../classes/Component";
import SpotifyEmbed from "./SpotifyEmbed";
import PlaylistSelector from "./PlaylistSelector";
import feed from 'feed'
import {random} from "../../utils/math";
import WorkWindow from "./WorkWindow";
import CreativePlaylist from "./CreativePlaylist";
import GSAP from "gsap";
import {setRootAnimationColors} from "../../utils/dom";
import FeedBgImages from "./FeedBgImages";

const activeClassName = "is-active";

const closedHeight = "64px"
const openedHeight = "100%"

export default class extends Component {
  constructor({onToggle}) {
    super({
      element: ".playlist-catalogue-wrapper",
      elements: {
        picker: ".playlist-catalogue",
        pickerBody: '.playlist-catalogue-body',
        choosePlaylist: document.querySelector(".choose-playlist"),
        playlistClose: ".c-button",
        playlists: "[data-playlist-index]",
        nextUpButton: document.querySelector('.feed-card__body-next-up'),
        randomizeButton: document.querySelector('.feed-card__body-randomize-button'),
        creativePlaylists: '.playlist-catalogue-body-creative-playlist',
        creativePlaylistsTitle: '.playlist-catalogue-body-playlists-intro',
        welcomeText: '.playlist-catalogue-body-welcome-text',
        butterflies: '.playlist-catalogue-wrapper-butterfly',
        navIndicator: document.querySelector('.c-nav__indicator__inner'),
      },
    });

    this.classes = {
      navAnimated: 'is-animated'
    }

    this.setupControlEvents();
    this.setupPlaylistEvents();
    this.setupEntryTimeline();

    this.spotifyEmbed = new SpotifyEmbed();
    this.playlistSelector = new PlaylistSelector();
    this.workWindow = new WorkWindow();
    this.bgImages = new FeedBgImages();

    this.currentPlaylist = this.getInitialPlaylist();
    this.displayData();

    this.elements.creativePlaylists.forEach(playlistElement => {
      new CreativePlaylist(playlistElement)
    })

    this.onToggle = onToggle;
  }

  setElementBezier(element, bezier) {
    element.style.transitionTimingFunction = bezier;
  }

  setupEntryTimeline() {
    const {creativePlaylistsTitle, creativePlaylists, welcomeText, picker, playlistClose, butterflies} = this.elements;

    const timeline = GSAP.timeline({
      paused: true
    });

    //Show picker
    this.setElementBezier(picker, 'cubic-bezier(0.42,0,1,1)')

    timeline.fromTo(picker, {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.1
    })

    timeline.addLabel("showTimeline", ">");

    timeline.to(playlistClose, {
      opacity: 1,
      duration: 0
    })

    //Expand picker
    this.setElementBezier(picker, 'cubic-bezier(0.24, 0.6, 0.25, 1)')

    timeline.fromTo(picker, {
      height: closedHeight
    }, {
      height: openedHeight,
      duration: 1
    }, "showTimeline")


    timeline.fromTo(butterflies, {
      scale: 0
    }, {
      scale: 1,
      duration: 0.8
    }, "showTimeline")

    timeline.to(butterflies, {
      opacity: 1,
      duration: 0.3
    }, "showTimeline")

    //Show creative playlists title
    const firstTitle = creativePlaylistsTitle[0];

    this.setElementBezier(firstTitle, 'cubic-bezier(0.24, 0.6, 0.25, 1)')

    timeline.fromTo(firstTitle, {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.3
    }, "showTimeline+=0.5")

    //Show creative playlists rows
    const creativePlaylistsArray = Array.from(creativePlaylists);

    creativePlaylistsArray.forEach(playlist => {
      this.setElementBezier(playlist, 'cubic-bezier(0.24, 0.6, 0.25, 1)')
    })

    const firstRow = creativePlaylistsArray.slice(0, 4);

    timeline.fromTo(firstRow, {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.3,
      stagger: 0.1
    }, "showTimeline+=0.5");

    const secondRow = creativePlaylistsArray.slice(4, 8);

    timeline.fromTo(secondRow, {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.3,
      stagger: 0.1
    }, "showTimeline+=0.6");

    //Show welcome text
    this.setElementBezier(welcomeText, 'cubic-bezier(0.24, 0.6, 0.25, 1)')

    timeline.fromTo(welcomeText, {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.3
    }, "showTimeline+=0.6")

    this.entryTimeline = timeline
  }

  async showPlaylistPicker() {
    this.element.classList.add(activeClassName);
    this.entryTimeline.play();

    this.onToggle(true);
  }


  playCloseAnimation() {
    const {creativePlaylistsTitle, welcomeText, picker, playlistClose, butterflies} = this.elements;

    const targets = [creativePlaylistsTitle[0], welcomeText, "[data-playlist-index]"]

    const timeline = GSAP.timeline({
      onComplete: this.hidePlaylistPicker
    });

    timeline.to(targets, {
      opacity: 0,
      duration: 0.2
    })

    timeline.to(playlistClose, {
      opacity: 0,
      duration: 0
    })


    timeline.fromTo(butterflies, {
      scale: 1,
    }, {
      scale: 0,
      duration: 0.3
    }, 0)

    timeline.to(butterflies, {
      opacity: 0,
      duration: 0.15
    }, 0)

    timeline.fromTo(picker, {
      height: openedHeight,
    }, {
      height: closedHeight,
      duration: 0.4
    })

    timeline.to(picker, {
      opacity: 0,
      duration: 0.15
    });
  }

  async hidePlaylistPicker() {
    const {pickerBody, playlists} = this.elements;

    this.element.classList.remove(activeClassName);

    this.entryTimeline.pause();
    this.entryTimeline.seek(0);

    GSAP.to(Array.from(playlists).slice(8), {opacity: 1});

    pickerBody.scrollTo(0, 0);

    this.onToggle(false);
  }

  setupControlEvents() {
    this.elements.choosePlaylist.addEventListener("click", () => {
      this.showPlaylistPicker();
    })

    this.elements.playlistClose.addEventListener("click", () => {
      this.playCloseAnimation();
    })

    this.elements.nextUpButton.addEventListener("click", () => {
      const {identifier} = this.getNextPlaylist();

      this.currentPlaylist = identifier;
      this.displayData();
      this.hidePlaylistPicker();
    })

    this.elements.randomizeButton.addEventListener("click", () => {
      const identifier = this.getRandomPlaylist();

      this.currentPlaylist = identifier;
      this.displayData();
      this.hidePlaylistPicker();
    })
  }

  setupPlaylistEvents() {
    this.elements.playlists.forEach((playlist) => {
      playlist.addEventListener("click", () => {
        this.currentPlaylist = `${playlist.dataset.playlistType}-${playlist.dataset.playlistIndex}`
        this.displayData();
        this.hidePlaylistPicker();
      })
    })
  }

  getInitialPlaylist() {
    const search = new URLSearchParams(window.location.search);
    const urlId = search.get('pid');
    if (!urlId) return 'creative-0';

    const creativeIndex = feed.creativePlaylists.findIndex(playlist => playlist.id === urlId);

    if (creativeIndex !== -1) {
      return `creative-${creativeIndex}`
    }

    const nullIndex = feed.nullPlaylists.findIndex(playlist => playlist.id === urlId);

    if (nullIndex !== -1) {
      return `null-${nullIndex}`
    }

    return 'creative-0';
  }

  getCurrentPlaylistData() {
    const [type, index] = this.currentPlaylist.split('-');

    let playlistsArray = feed.creativePlaylists;

    if (type === 'null') {
      playlistsArray = feed.nullPlaylists;
    }

    return playlistsArray[index]
  }

  getNextPlaylist() {
    const [type, _index] = this.currentPlaylist.split('-');

    const index = Number(_index);

    if (type === 'creative') {
      const nextPlaylist = feed.creativePlaylists[index + 1];

      if (nextPlaylist) {
        return {
          playlist: nextPlaylist,
          identifier: `creative-${index + 1}`
        }
      } else {
        return {
          playlist: feed.nullPlaylists[0],
          identifier: `null-0`
        }
      }
    } else {
      const nextPlaylist = feed.nullPlaylists[index + 1];

      if (nextPlaylist) {
        return {
          playlist: nextPlaylist,
          identifier: `null-${index + 1}`
        }
      } else {
        return {
          playlist: feed.creativePlaylists[0],
          identifier: `creative-0`
        }
      }
    }
  }

  getRandomPlaylist() {
    const index = Math.floor(random(0, feed.creativePlaylists.length + feed.nullPlaylists.length - 2));

    const type = index < feed.creativePlaylists.length ? 'creative' : 'null';
    const correctIndex = index < feed.creativePlaylists.length ? index : index - feed.creativePlaylists.length;

    return `${type}-${correctIndex}`
  }

  displayData() {
    const playlistData = this.getCurrentPlaylistData();
    window.history.pushState("", "", `/feed?pid=${playlistData.id}`)

    const nextPlaylist = this.getNextPlaylist().playlist;

    const selectedPlaylist = this.getCurrentPlaylistData();

    //Nav animation
    this.elements.navIndicator.classList.add(this.classes.navAnimated);
    setRootAnimationColors(selectedPlaylist.gradient_start, selectedPlaylist.gradient_end);

    //Update bg image
    this.bgImages.displayPlaylist(selectedPlaylist.id);

    //Display data
    this.spotifyEmbed.setEmbedLink(selectedPlaylist);
    this.playlistSelector.setPlaylistSelector(selectedPlaylist, nextPlaylist.playlist_name);
    this.workWindow.displayPlaylist(selectedPlaylist);
  }

  hide(){
    this.elements.navIndicator.classList.remove(this.classes.navAnimated);
  }
}