import { Plane, TextureLoader } from "curtainsjs";
import AutoBind from "../../utils/bind";
import vertex from "./shaders/vertex.glsl";
import fragment from "./shaders/fragment.glsl";

export default class {
  constructor({ curtains, plane, textureId }) {
    AutoBind(this);

    this.mouseTargetX = 0;
    this.mouseTargetY = 0;

    this.mouseX = 0;
    this.mouseY = 0;

    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.curtains = curtains;
    this.planeEl = plane;

    this.textures = window.TEXTURES;
    this.textureId = textureId;

    this.threshhold = 40;

    this.params = {
      vertexShader: vertex,
      fragmentShader: fragment,
      widthSegments: 1,
      heightSegments: 1,
      transparent: true,
      uniforms: {
        mouse: {
          name: "uMouse",
          type: "2f",
          value: [1, 1],
        },
      },
    };

    this.createPlanes();
    this.createTextures();
    this.canvas = document.querySelector("#canvas");
  }

  createPlanes() {
    // this.planes = [];

    this.plane = new Plane(this.curtains, this.planeEl, this.params);

    // this.plane.onRender(() => {
    //   this.update();
    // });

    // mapEach(planes, (plane) => {
    //   this.planes.push(new Plane(this.curtains, plane, this.params));
    // });
  }

  createTextures() {
    const mainTexture = this.textures[`main${this.textureId}`];
    const depthTexture = this.textures[`depth${this.textureId}`];

    this.plane.addTexture(mainTexture);
    this.plane.addTexture(depthTexture);
  }

  onTouchMove(event) {
    let halfX = innerWidth / 2;
    let halfY = innerHeight / 2;

    if (event.clientX <= 88 && event.clientY <= 88) {
      this.mouseTargetX = 0;
      this.mouseTargetY = 0;
    } else {
      this.mouseTargetX = (halfX - event.clientX) / halfX;
      this.mouseTargetY = (halfY - event.clientY) / halfY;
    }
  }

  onResize() {
    this.plane.updatePosition();
  }

  update() {
    if (this.canvas.classList.contains("show")) {
      this.mouseX += (this.mouseTargetX - this.mouseX) * 0.05;
      this.mouseY += (this.mouseTargetY - this.mouseY) * 0.05;
    } else {
      this.mouseX = 0;
      this.mouseY = 0;
    }

    this.plane.uniforms.mouse.value = [
      this.mouseX / this.threshhold,
      -this.mouseY / this.threshhold,
    ];

    // console.log(this.plane.uniforms.mouse.value);
  }
}
