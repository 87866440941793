import Component from "../classes/Component";

export default class extends Component {
  constructor() {
    super({
      element: "[data-nav]",
      elements: {
        dropdownToggle: "[data-nav-dropdown-toggle]",
        dropdown: "[data-nav-dropdown]",
        soundToggle: "[data-sound-toggle]",
        fullscreenToggle: "[data-fullscreen-toggle]",
      },
    });

    this.addEventListeners();
  }

  openInFullscreen() {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  addEventListeners() {
    const { dropdownToggle, dropdown, soundToggle, fullscreenToggle } =
      this.elements;

    dropdownToggle.addEventListener("click", () => {
      dropdown.classList.toggle("active");
    });

    soundToggle.addEventListener("change", (e) => {
      if (e.target.checked) {
        this.emit("soundOff");
      } else {
        this.emit("soundOn");
      }
    });

    fullscreenToggle.addEventListener("change", (e) => {
      if (e.target.checked) {
        this.openInFullscreen();
      } else {
        this.closeFullscreen();
      }
    });

    // check for fullscreen off event

    document.addEventListener("fullscreenchange", (e) => {
      if (!document.fullscreenElement) {
        fullscreenToggle.checked = false;
      }
    });
  }
}
