precision highp float;

// default mandatory variables
attribute vec3 aVertexPosition;
attribute vec2 aTextureCoord;

uniform mat4 uMVMatrix;
uniform mat4 uPMatrix;

// our texture matrices
// displacement texture does not need to use them
uniform mat4 uTextureMatrix;

// custom variables
varying vec3 vVertexPosition;
varying vec2 vTextureCoord;

varying vec2 vActiveTextureCoord;

void main() {
    vec3 pos = aVertexPosition;
    gl_Position = uPMatrix * uMVMatrix * vec4(pos, 1.0);

    // varyings
		vActiveTextureCoord = (uTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;

    // use original texture coords for our displacement
    vTextureCoord = aTextureCoord;
    vVertexPosition = aVertexPosition;
}
