import Component from "../../classes/Component";

const hiddenClassName = "is-hidden";

export default class extends Component {
  constructor() {
    const parentElement = "[data-card-id='playlist-info']";
    super({
      element: parentElement,
      elements: {
        image: `[data-owner-image]`,
        name: `[data-name]`,
        description: `[data-description]`,
        owner: `[data-owner]`,
        jobTitle: `[data-job-title]`,
        link: `[data-link]`,
        creativePractice: `[data-creative-practice]`,
        hideInfo: '.c-button'
      },
    });

    this.elements.hideInfo.addEventListener('click', this.hide)
  }

  setPlaylistInfo(playlist){
    this.elements.image.src = playlist.bitmoji;
    this.elements.image.alt = playlist.alias;
    this.elements.name.textContent = `(${playlist.playlist_name})`;
    this.elements.description.textContent = playlist.playlist_info;
    this.elements.owner.textContent = playlist.alias;
    this.elements.jobTitle.textContent = playlist.job_title;
    this.elements.link.href = playlist.external_link;
    this.elements.creativePractice.textContent = playlist.creative_practice;

    this.element.classList.remove(hiddenClassName)
  }

  hide(){
    this.element.classList.add(hiddenClassName)
  }
}