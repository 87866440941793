const Draggabilly = require("draggabilly");

export default class Draggable {
  constructor(element) {
    const draggie = new Draggabilly(element, {
      handle: "[data-drag-header]",
    });

    this.draggie = draggie;
  }
}
