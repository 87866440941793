import Component from "../../classes/Component";
import WorkVideo from "./WorkVideo";

const activeClassName = "is-active";
const hiddenClassName = "is-hidden";

export default class extends Component {
  constructor() {
    super({
      element: ".feed-card--work-window",
      elements: {
        media: ".feed-card__body-media-wrapper",
        header: ".feed-card__header-text",
      },
    });

    this.idToElementMapping = {};
    this.videoInstances = {};

    this.elements.media.forEach(media => {
      const playlistId = media.dataset.playlist;
      this.idToElementMapping[playlistId] = media;

      if(media.dataset.isVideo){
        this.videoInstances[playlistId] = new WorkVideo(media);
      }
    })

    this.activeElement = null;
  }

  resetPosition(){
    this.element.style.left = `50%`
    this.element.style.top = `50%`
    this.element.style.transform = `translate(-50%, -50%)`
  }

  displayPlaylist(playlist){
    if(this.activeElement){
      if(this.idToElementMapping[this.activeElement]){
        this.idToElementMapping[this.activeElement].classList.remove(activeClassName)
      }

      if(this.videoInstances[this.activeElement]){
        this.videoInstances[this.activeElement].resetVideo()
      }
    }

    if(playlist.category === 'null') {
      this.element.classList.add(hiddenClassName)
    }else {
      this.resetPosition();

      this.activeElement = playlist.id;

      this.idToElementMapping[this.activeElement].classList.add(activeClassName)

      const extension = playlist.mediaIsVideo ? 'mp4' : 'jpg'
      this.elements.header.textContent = `${playlist.alias}.${extension}`

      if (this.videoInstances[this.activeElement]) {
        this.videoInstances[this.activeElement].playVideo()
      }

      this.element.classList.remove(hiddenClassName)
    }
  }
}