import Component from "../../classes/Component";
// import GSAP from "gsap";
import GSAP from "../../utils/motionpath";
import {random} from "../../utils/math";

export default class extends Component {
  constructor() {
    super({
      element: ".idle-feed-state",
      elements: {
        butterfly: '.idle-feed-state-butterfly',
        image: '.idle-feed-state-image',
        svg: '.idle-feed-state-svg',
        path: '.idle-feed-state-svg path',
        dancingButterfly: '[dancing-butterfly]'
      },
    });


    this.setupTimeline1()
    this.setupTimeline2()

    this.activeTimeline = this.getRandomActiveTimeline();
  }

  getRandomActiveTimeline(){
    const randomNumber = random(0, 1) > 0.5 ? 0 : 1;

    return randomNumber === 0 ? this.timeline1 : this.timeline2;
  }

  setupTimeline1(){
    const timeline = GSAP.timeline({
      paused: true
    });

    timeline.addLabel("start");

    timeline.fromTo(this.elements.butterfly, {
      scale: 0,
      opacity: 0
    }, {
      scale: 1,
      opacity: 1,
      duration: 0.6,
    }, "start")

    timeline.fromTo(this.elements.image, {
      scale: 0.5,
      opacity: 0,
      y: "-50%"
    }, {
      scale: 1,
      opacity: 1,
      duration: 0.6,
      y: "-50%"
    }, "start")

    this.timeline1 = timeline;
  }

  setupTimeline2(){
    const { svg, path, dancingButterfly } = this.elements;

    // get the point at a certain length along a path
    const point = path.getPointAtLength(0);

    // get the position and size of the SVG element on the page
    const svgRect = svg.getBoundingClientRect();

    // get the viewBox and aspect ratio of the SVG element
    const viewBox = svg.viewBox.baseVal;
    const aspectRatio = viewBox.width / viewBox.height;

    // calculate the position of the point relative to the window
    const svgWidth = svgRect.width;
    const svgHeight = svgWidth / aspectRatio;
    const svgX = svgRect.left + (svgRect.width - svgWidth) / 2;
    const svgY = svgRect.top + (svgRect.height - svgHeight) / 2;

    const windowX = svgX + (point.x - viewBox.x) / viewBox.width * svgWidth;
    const windowY = svgY + (point.y - viewBox.y) / viewBox.height * svgHeight;

    const timeline = GSAP.timeline({
      paused: true
    });

    timeline.addLabel("start");

    timeline.fromTo(this.elements.image, {
      scale: 0.5,
      opacity: 0,
      y: "-50%"
    }, {
      scale: 1,
      opacity: 1,
      duration: 0.6,
      y: "-50%"
    }, "start")

    timeline.fromTo(dancingButterfly, {
      scale: 0,
      opacity: 0
    }, {
      scale: 1,
      opacity: 1,
      duration: 0.6,
    }, "start")

    timeline.fromTo(dancingButterfly, {
      x: `${windowX - 112}px`,
      y: `${windowY - 81}px`,
      rotate: 165
    },{
      duration: 20,
      repeat: -1,
      ease: "linear",
      motionPath:{
        fromCurrent: false,
        path: path,
        align: path,
        autoRotate: 90,
        alignOrigin: [0.5, 0.5],
      }
    });

    this.timeline2 = timeline;
  }

  show(){
    this.activeTimeline.play();
  }

  hide(){
    if(this.activeTimeline === this.timeline1){
      this.activeTimeline.reverse();
    }else{
      this.activeTimeline.pause();

      const exitTl = GSAP.timeline({
        onComplete: () => {
          this.activeTimeline.seek(0);
        }
      });

      exitTl.addLabel("start");

      exitTl.to(this.elements.dancingButterfly, {
        scale: 0,
        opacity: 0,
        duration: 0.6,
      })

      exitTl.to(this.elements.image, {
        scale: 0.5,
        opacity: 0,
        y: "-50%"
      }, "start")
    }

    this.activeTimeline = this.getRandomActiveTimeline();
  }
}