import Component from "../../classes/Component";
import PlaylistInfo from "./PlaylistInfo";

const hiddenClassName = "feed-card__footer--is-hidden";

const initialPosition = {
  left: 112,
  top: 240
}

export default class extends Component {
  constructor() {
    super({
      element: "[data-card-id='spotify-embed']",
      elements: {
        iframe: ".spotify-embed",
        aboutPlaylist: ".feed-card__footer",
        header: ".feed-card__header-text",
      },
    });

    this.playlistInfo = new PlaylistInfo();
    this.currentPlaylist = null;

    this.elements.aboutPlaylist.addEventListener('click', this.showPlaylistInfo)
  }

  setEmbedLink(playlist){
    const { header, aboutPlaylist } = this.elements;

    this.currentPlaylist = playlist;
    this.elements.iframe.src = playlist.embedLink;
    header.textContent = playlist.playlist_name

    if(playlist.category === 'creative'){
      aboutPlaylist.classList.remove(hiddenClassName)
    }else{
      aboutPlaylist.classList.add(hiddenClassName)
    }

    this.element.style.left = `${initialPosition.left}px`
    this.element.style.top = `${initialPosition.top}px`
  }

  showPlaylistInfo(){
    this.playlistInfo.setPlaylistInfo(this.currentPlaylist)
  }
}