import AutoBind from "../utils/bind";
import { setRootAnimationColors, setRootColors } from "../utils/dom";
import EventEmitter from "events";

export default class Page extends EventEmitter {
  constructor({ classes, element, elements }) {
    super();
    AutoBind(this);

    this.classes = {
      ...classes,
    };

    this.selector = element;
    this.selectorChildren = {
      ...elements,
    };
    this.draggies = [];
    this.create();

    this.body = document.querySelector("body");
    this.gradient = document.querySelector("[data-gradient]");
  }

  getGradientValues(el) {
    const topColor = el.getAttribute("data-start-gradient") || null;
    const bottomColor = el.getAttribute("data-end-gradient") || null;

    return {
      topColor,
      bottomColor,
    };
  }

  create() {
    if (this.selector instanceof HTMLElement) {
      this.element = this.selector;
    } else {
      this.element = document.querySelector(this.selector);
    }

    this.elements = {};

    Object.keys(this.selectorChildren).forEach((key) => {
      const entry = this.selectorChildren[key];

      if (
        entry instanceof HTMLElement ||
        entry instanceof NodeList ||
        Array.isArray(entry)
      ) {
        this.elements[key] = entry;
      } else {
        this.elements[key] = this.element.querySelectorAll(entry);

        if (this.elements[key].length === 0) {
          this.elements[key] = null;
        } else if (this.elements[key].length === 1) {
          this.elements[key] = this.element.querySelector(entry);
        }
      }
    });
  }

  show(el) {
    if (el) {
      // this.body.style.height = `${getOffset(el).height}px`;

      const { topColor, bottomColor } = this.getGradientValues(el);

      setRootColors(topColor, bottomColor);
      this.gradient.classList.remove("is-animated");
    } else {
      // this.body.style.height = `${getOffset(this.element).height}px`;
      setRootAnimationColors("#FFFFFF", "#9F9F9F");
      this.gradient.classList.add("is-animated");

      const { topColor, bottomColor } = this.getGradientValues(this.element);
      setRootColors(topColor, bottomColor);
    }

    return Promise.resolve();
  }

  hide() {
    return Promise.resolve();
  }

  createDraggable() {
    mapEach(this.elements.draggable, (element) => {
      const draggable = new Draggable(element);
      this.draggies.push(draggable.draggie);
    });
  }

  onResize(el) {
    // if (el) {
    //   this.body.style.height = `${getOffset(el).height}px`;
    // } else {
    //   this.body.style.height = `${getOffset(this.element).height}px`;
    // }
  }

  /**
   * Events
   */

  /**
   * Update
   */

  update() {}
}
