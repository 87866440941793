import GSAP from "gsap";
import CustomEase from "./CustomEase";
GSAP.registerPlugin(CustomEase);

export const PRELOADER_CARD_SCALE_IN = CustomEase.create(
  "preloader-scale",
  "0.24, 0.6, 0.25, 1"
);

export const PRELOADER_CARD_SCALE_OUT = CustomEase.create(
  "preloader-scale",
  "0.12, 0.02, 1, 1"
);

export const PRELOADER_OUT = CustomEase.create(
  "preloader-scale",
  "0.63, 0, 0.4, 0.99"
);

export const HERO_ENTRY_1 = CustomEase.create(
  "preloader-scale",
  "0.25, 0.1, 0.25, 1"
);

export const HERO_ENTRY_2 = CustomEase.create(
  "preloader-scale",
  "0.42, 0, 0.58, 1"
);

export const HERO_ENTRY_3 = CustomEase.create(
  "preloader-scale",
  "0.24, 0.4, 0.25, 1"
);

export const BUTTERFLY_EXIT = CustomEase.create(
  "preloader-scale",
  "0.2, 0.02, 1, 1"
);
