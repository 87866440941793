import Component from "../../classes/Component";

export default class extends Component {
  constructor(element) {
    super({
      element,
      elements: {
        video: 'video'
      }
    });

    this.setupEventListeners();
  }

  setupEventListeners(){
    this.element.addEventListener('click', () => {
      if(this.element.dataset.playState === "playing"){
        this.pauseVideo();
      }else{
        this.playVideo()
      }
    })
  }

  resetVideo(){
    this.pauseVideo();
    this.elements.video.currentTime = 0;
  }

  pauseVideo(){
    this.element.dataset.playState = "paused"
    this.elements.video.pause();
  }

  playVideo(){
    this.element.dataset.playState = "playing"
    this.elements.video.play();
  }
}