import Page from "../../classes/Page";
import PlaylistCatalogue from "../../components/Feed/PlaylistCatalogue";
import { copyTextToClipboard } from "../../utils/clipboard";
import MobilePlaylist from "../../components/Feed/MobilePlaylist";
import MobilePlaylistInfo from "../../components/Feed/MobilePlaylistInfo";
import IdleFeedState from "../../components/Feed/IdleFeedState";
import throttle from "lodash/throttle";

const idleStateDuration = 1000 * 30;

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "feed--active",
      },
      element: ".feed",
      elements: {
        wrapper: ".feed__wrapper",
        content: ".feed__wrapper__inner",
        cards: "[data-card-id]",
        sharePage: "[data-share-desktop]",
        sharePageMobile: "[data-share-mobile]",
        mobilePlaylists: ".mobile-playlist",
        backToTop: ".back-to-top",
      },
    });

    this.cardOrder = [
      "work-window",
      "spotify-embed",
      "playlist-selector",
      "playlist-info",
    ];
    this.cardToIdMapping = {};
    this.elements.cards.forEach((card) => {
      this.cardToIdMapping[card.dataset.cardId] = card;
    });

    this.setCardZIndexes();
    this.setupCardDragEvents();
    this.setupShareLogic();
    this.setupBackToTop();

    this.mobilePlaylistInfo = new MobilePlaylistInfo();
  }

  setupBackToTop() {
    this.elements.backToTop.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }

  handleClickAbout(playlist) {
    this.mobilePlaylistInfo.displayPlaylist(playlist);
  }

  show() {
    this.elements.mobilePlaylists.forEach((playlist) => {
      new MobilePlaylist({
        element: playlist,
        onClickAbout: this.handleClickAbout,
      });
    });

    this.playlistCatalogue = new PlaylistCatalogue({
      onToggle: this.onToggleCatalogue,
      page: this.element,
    });

    this.element.classList.add(this.classes.active);

    //Setup idle feed state
    this.idleFeedState = new IdleFeedState();
    this.catalogueState = { visible: false };
    this.setupIdleFeedStateListener();
    this.startIdleFeedState();

    return super.show();
  }

  async hide() {
    this.playlistCatalogue.hide();

    this.element.classList.remove(this.classes.active);

    return super.hide();
  }

  onResize() {
    super.onResize();
  }

  update() {
    super.update();
  }

  setCardZIndexes() {
    this.cardOrder.forEach((cardId, index) => {
      this.cardToIdMapping[cardId].style.zIndex = `${index + 10}`;
    });
  }

  startIdleFeedState() {
    clearTimeout(this.feedStateTimeout);

    this.feedStateTimeout = setTimeout(() => {
      this.idleFeedState.show();
    }, idleStateDuration);
  }

  setupIdleFeedStateListener() {
    const throttled = throttle(() => {
      this.idleFeedState.hide();

      if (this.catalogueState.visible) return;

      this.startIdleFeedState();
    }, 1000);

    this.element.addEventListener("mousemove", throttled);
  }

  onToggleCatalogue(visible) {
    this.catalogueState.visible = visible;

    if (visible) {
      clearTimeout(this.feedStateTimeout);
    } else {
      this.startIdleFeedState();
    }
  }

  setupCardDragEvents() {
    this.draggies.forEach((draggie) => {
      const { element } = draggie;
      const cardId = element.dataset.cardId;

      draggie.on("dragStart", () => {
        this.cardOrder = this.cardOrder.filter((id) => id !== cardId);
        this.cardOrder.push(cardId);
        this.setCardZIndexes();
      });
    });
  }

  setupShareLogic() {
    this.elements.sharePage.addEventListener("click", () => {
      copyTextToClipboard(window.location.href).then(() => {
        const buttonText =
          this.elements.sharePage.querySelector(".c-button__text");

        buttonText.textContent = "Link copied!";

        setTimeout(() => {
          buttonText.textContent = "Share this page";
        }, 1000);
      });
    });

    this.elements.sharePageMobile.addEventListener("click", () => {
      navigator
        .share({
          title: "Null FM",
          text: "This is pretty cool",
          url: window.location.href,
        })
        .then(() => console.log("Successful share! 🎉"))
        .catch((err) => console.error(err));
    });
  }
}
