import Component from "../../classes/Component";

export default class extends Component {
  constructor() {
    super({
      element: ".feed-bg-images",
      elements: {
        images: '.feed-bg-image',
      },
    });

    this.classes = {
      active: 'is-active'
    };

    this.elementsMap = {};

    this.elements.images.forEach((image) => {
      this.elementsMap[image.dataset.playlist] = image;
    })
  }

  displayPlaylist(playlistId){
    if(this.activePlaylist){
      this.elementsMap[this.activePlaylist].classList.remove(this.classes.active);
    }

    this.activePlaylist = playlistId;
    this.elementsMap[playlistId].classList.add(this.classes.active);
  }
}