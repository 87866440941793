import Component from "../../classes/Component";
import WorkVideo from "./WorkVideo";

const activeClassName = "is-active"
const visibleClassName = "is-visible"

export default class extends Component {
  constructor() {
    super({
      element: ".mobile-playlist-info",
      elements: {
        title: '[data-title]',
        description: '[data-description]',
        owner: '[data-owner]',
        jobTitle: '[data-job-title]',
        externalLink: '[data-link]',
        creativePractice: '[data-creative-practice]',
        mediaElements: '[data-playlist]',
        closeButton: '.mobile-playlist-info-close',
        overlay: '.mobile-playlist-info__overlay',
      },
    });

    this.mediaElements = {};
    this.videoInstances = {};

    this.elements.mediaElements.forEach(media => {
      const playlistId = media.dataset.playlist;
      this.mediaElements[playlistId] = media;

      if(media.dataset.isVideo){
        this.videoInstances[playlistId] = new WorkVideo(media);
      }
    })

    this.setupEventListeners()
  }

  setupEventListeners(){
    this.elements.closeButton.addEventListener('click', this.hidePlaylistInfo)

    this.elements.overlay.addEventListener('click', this.hidePlaylistInfo)
  }

  displayMedia(playlist) {
    //Update media elements
    if(this.activeElement){
      const currentActiveMedia = this.mediaElements[this.activeElement];
      if(currentActiveMedia){
        currentActiveMedia.classList.remove(activeClassName)
      }

      const currentVideoInstance = this.videoInstances[this.activeElement];

      if(currentVideoInstance){
        currentVideoInstance.resetVideo()
      }
    }

    if (this.videoInstances[playlist.id]) {
      this.videoInstances[playlist.id].playVideo()
    }

    if(this.mediaElements[playlist.id]){
      this.mediaElements[playlist.id].classList.add(activeClassName)
    }
  }

  displayPlaylist(playlist){
    //Update content
    this.elements.title.textContent = playlist.playlist_name;
    this.elements.description.textContent = playlist.playlist_info;
    this.elements.owner.textContent = playlist.alias;
    this.elements.jobTitle.textContent = playlist.job_title;
    this.elements.externalLink.href = playlist.external_link;
    this.elements.creativePractice.textContent = playlist.creative_practice;

    //Display modal
    this.element.classList.add(visibleClassName)

    this.displayMedia(playlist);

    //Update state
    this.activeElement = playlist.id;

    document.body.classList.add('no-scroll')
  }

  hidePlaylistInfo(){
    this.element.classList.remove(visibleClassName);

    document.body.classList.remove('no-scroll');
  }
}